import usersIcon from "../../../static/menu_icons/users.svg";
import videoIcon from "../../../static/menu_icons/video.svg";
import eventIcon from "../../../static/menu_icons/events.svg";
import paymentIcon from "../../../static/menu_icons/payments.svg";
import viewsIcon from "../../../static/menu_icons/views.svg";
import statisticsIcon from "../../../static/menu_icons/statistics.svg";
import notificationIcon from "../../../static/menu_icons/notifications.svg";
import categoryIcon from "../../../static/menu_icons/category.svg";
import adsIcon from "../../../static/menu_icons/ads.svg";
import versionIcon from "../../../static/menu_icons/version.svg";
import sliderIcon from "../../../static/menu_icons/slider.svg";
import blockIcon from "../../../static/menu_icons/block.svg";
import codesIcon from "../../../static/menu_icons/codes.svg";
import galleryIcon from "../../../static/menu_icons/gallery.svg";
import festivalIcon from "../../../static/menu_icons/festival.svg";
import invoiceIcon from "../../../static/menu_icons/invoice.svg";
import presentIcon from "../../../static/menu_icons/present.svg";
import groupTicketIcon from "../../../static/menu_icons/groupTicket.svg";
import packetIcon from "../../../static/menu_icons/packet.svg";
import helpIcon from "../../../static/menu_icons/help.svg";
import { allowInvoices } from "../../../theme-variables";

export default [
  { label: "Dodawanie wideo", icon: videoIcon, key: "videos" },
  { label: "Repertuar", icon: eventIcon, key: "events" },
  { label: "Festiwal", icon: festivalIcon, key: "festival" },
  { label: "Galerie", icon: galleryIcon, key: "galleries" },
  { label: "Użytkownicy", icon: usersIcon, key: "users" },
  {
    label: "Ograniczenia",
    icon: blockIcon,
    key: "limitations",
  },
  { label: "Lista płatności", icon: paymentIcon, key: "payments" },
  {
    label: "Faktury",
    icon: invoiceIcon,
    key: "invoices",
    hide: !allowInvoices,
  },
  { label: "Widoki", icon: viewsIcon, key: "views" },
  {
    label: "Powiadomienia",
    icon: notificationIcon,
    key: "notifications",
  },
  // {
  //   label: "Ustawienia kont premium",
  //   icon: premiumIcon,
  //   key: "premium",
  // },
  { label: "Statystyki", icon: statisticsIcon, key: "statistics" },
  {
    label: "Kategorie filmów",
    icon: categoryIcon,
    key: "categories",
  },
  {
    label: "Slider",
    icon: sliderIcon,
    key: "slider_settings",
  },
  {
    label: "Reklamy",
    icon: adsIcon,
    key: "advertisement_settings",
  },
  { label: "Prezenty", icon: presentIcon, key: "gifts" },
  { label: "Bilety grupowe", icon: groupTicketIcon, key: "group_tickets" },
  { label: "Pakiety", icon: packetIcon, key: "packet" },
  {
    label: "Kody rabatowe",
    icon: codesIcon,
    key: "promotion_codes",
  },
  {
    label: "Pomoc",
    icon: helpIcon,
    key: "help",
  },
  {
    label: "Informacje o wersji",
    icon: versionIcon,
    key: "about_versions",
  },
];
