import vod, { tokenConfig } from "../apis/vod";
import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_HIDDEN_EVENTS_SUCCESS,
  GET_HIDDEN_EVENTS_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  EVENTS_LOADING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  EVENT_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";
import moment from "moment";

export const getEvents =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch(setEventsLoading());
    vod
      .get(`/api/events`, {
        params: props,
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_EVENTS_SUCCESS,
          payload: res.data.events,
        });
      })
      .catch((err) => {
        handleErrors(err, dispatch, GET_EVENTS_FAILURE);
      });
  };

export const getHiddenEvents =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch(setEventsLoading());
    vod
      .get(`/api/events/hidden`, {
        params: props,
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_HIDDEN_EVENTS_SUCCESS,
          payload: res.data.events,
        });
      })
      .catch((err) => {
        handleErrors(err, dispatch, GET_HIDDEN_EVENTS_FAILURE);
      });
  };

export const getEvent = (id) => (dispatch, getState) => {
  dispatch(setEventsLoading());
  vod
    .get(`/api/events/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EVENT_SUCCESS,
        payload: res.data.event,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_EVENT_FAILURE);
    });
};

export const deleteEvent = (id) => (dispatch, getState) => {
  dispatch(setEventsLoading());
  vod
    .delete(`/api/events/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_EVENT_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_EVENT_FAILURE);
    });
};

export const addEvent = (props) => (dispatch, getState) => {
  dispatch(setEventsLoading());

  vod
    .post(`/api/events/`, normalizeDates(props), tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_EVENT_SUCCESS, payload: res.data.event });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ADD_EVENT_FAILURE, EVENT_FORM_ERRORS);
    });
};

export const updateEvent = (props, id) => (dispatch, getState) => {
  dispatch(setEventsLoading());
  vod
    .put(`/api/events/${id}`, normalizeDates(props), tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_EVENT_SUCCESS,
        payload: res.data.event,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_EVENT_FAILURE, EVENT_FORM_ERRORS);
    });
};
export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING,
  };
};
export const normalizeDates = (props) => {
  const { date_from, date_to } = props;
  props.date_from = date_from
    ? moment(date_from).format("YYYY-MM-DD")
    : undefined;
  props.date_to = date_to ? moment(date_to).format("YYYY-MM-DD") : undefined;
  return props;
};
