import React from "react";
import { TagOutlined } from "@ant-design/icons";
import i18n from "../../../i18n";
import Button from "../../form-components/Button";
import { withTranslation } from "react-i18next";
import vod from "../../../apis/vod";
import { Form, Input } from "antd";

class ActivateCodeForm extends React.Component {
  state = { code: "" };
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.code) {
      this.setState({ codeError: this.props.t("form.validate.required") });
      return;
    }

    vod
      .post(
        `/api/users/${this.props.userId}/codes`,
        { code: this.state.code.trim() },
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        // window.location.reload(false);
        window.location.href += "#code_activated";
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.formError && err.response.data.formError.code) {
          this.setState({ codeError: err.response.data.formError.code });
        } else {
          this.setState({
            codeError:
              "Wystąpił niespodziewany błąd. Prosimy spróbować ponownie.",
          });
        }
      });
  };
  onCodeChange = (e) => {
    this.setState({ code: e.target.value, codeError: "" });
  };
  render() {
    const { t } = this.props;
    return (
      <form
        onSubmit={(e) => this.onSubmit(e)}
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <Field
          allowClear
          name="code"
          value={this.state.code}
          onChange={this.onCodeChange}
          component={Input}
          required={true}
          prefix={<TagOutlined className="site-form-item-icon" />}
          placeholder={t("userpage.code")}
        /> */}
        <Form.Item
          validateStatus={this.state.codeError ? "error" : ""}
          help={this.state.codeError}
          required={true}
        >
          <Input
            placeholder={t("userpage.code")}
            id="error"
            prefix={<TagOutlined className="site-form-item-icon" />}
            onChange={this.onCodeChange}
            allowClear
            value={this.state.code}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            maxWidth: "300px",
            marginBotom: 0,
            marginLeft: 15,
          }}
        >
          {t("userpage.activate")}
        </Button>
      </form>
    );
  }
}

export default withTranslation()(ActivateCodeForm);
