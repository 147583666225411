import vod from "../apis/vod";
import history from "../history";
import {
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_FORM_ERRORS,
  CLEAR_SIGNUP,
  // ---
  SIGNIN_LOADING,
  AUTH_USER,
  AUTH_ADMIN,
  SIGNIN_FAILURE,
  SIGNIN_FORM_ERRORS,
  CLEAR_SIGNIN,
  // ---
  VERIFY_RESEND_LOADING,
  VERIFY_RESEND_SUCCESS,
  VERIFY_RESEND_FAILURE,
  // ---
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  UNAUTH_USER,
  USER_EDIT_FORM_ERRORS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_BY_ADMIN_SUCCESS,
  CLEAR_UPDATE_USER,
  // ---
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  UPDATE_MAILING,
} from "./_types";
import { tokenConfig } from "../apis/vod";
import handleErrors, { checkStatusCode } from "./handleErrors";
import { facebookPixel } from "../theme-variables";
import ReactPixel from "react-facebook-pixel";

export const signUpUser = (props) => (dispatch) => {
  dispatch({ type: SIGNUP_LOADING });
  vod
    .post(`/api/users`, props)
    .then(() => {
      dispatch({ type: SIGNUP_SUCCESS });
      if (facebookPixel)
        ReactPixel.track("CompleteRegistration", {
          content_name: "user registration",
        });
      history.push(`/rejestracja/sukces?email=${props.email}`);
    })
    .catch((err) => {
      handleErrors(err, dispatch, SIGNUP_FAILURE, SIGNUP_FORM_ERRORS);
    });
};

export const signInUser = (props, redirectTo) => (dispatch) => {
  const { email, password, remember } = props;
  dispatch({ type: SIGNIN_LOADING });
  vod
    .post(`/api/auth`, { email, password, remember })
    .then((res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch({ type: AUTH_USER, payload: res.data });
      if (redirectTo) history.push(redirectTo);
      else history.push("/");
    })
    .catch((err) => {
      if (err.response.data.statusCode === 403 && !!err.response.data.email)
        history.push(`/weryfikacja-konta?email=${email}`);
      if (err.response.data.statusCode === 403 && !err.response.data.email) {
        history.push(`/logowanie-link`);
        return;
      }
      handleErrors(err, dispatch, SIGNIN_FAILURE, SIGNIN_FORM_ERRORS);
    });
};

export const signInAdmin = (props, redirectTo) => (dispatch) => {
  const { login_token, login_email } = props;
  dispatch({ type: SIGNIN_LOADING });
  vod
    .post(`/api/auth/admin`, { login_token, login_email })
    .then((res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch({ type: AUTH_ADMIN, payload: res.data });
      if (redirectTo) history.push(redirectTo);
      else history.push("/");
    })
    .catch((err) => {
      if (err.response.data.statusCode === 403 && err.response.data.email)
        history.push(`/weryfikacja-konta?email=${err.response.data.email}`);
      if (err.response.data.statusCode === 422 && !err.response.data.email)
        window.location.href = `/logowanie?wrongToken=true`;
      handleErrors(err, dispatch, SIGNIN_FAILURE, SIGNIN_FORM_ERRORS);
    });
};

export const resendVerification = (props) => (dispatch) => {
  dispatch({ type: VERIFY_RESEND_LOADING });
  vod
    .post(`/api/verifications/new`, props)
    .then((res) => {
      dispatch({ type: VERIFY_RESEND_SUCCESS });
    })
    .catch((err) => {
      let { statusCode, message, resend } = err.response.data;
      message = checkStatusCode(statusCode, dispatch, message);
      dispatch({
        type: VERIFY_RESEND_FAILURE,
        payload: { message, resend },
      });
    });
};

export const verifyEmail = (props) => (dispatch) => {
  vod
    .post(`/api/verifications`, props)
    .then(() => {
      dispatch({ type: VERIFY_EMAIL_SUCCESS });
    })
    .catch((err) => {
      let { statusCode, message, resend, type } = err.response.data;
      message = checkStatusCode(statusCode, dispatch, message);
      dispatch({
        type: VERIFY_EMAIL_ERROR,
        payload: { message, resend, type },
      });
    });
};

export const updateUser = (props, id) => (dispatch, getState) => {
  const userId = id ? id : getState().auth.user.id;
  vod
    .put(`/api/users/${userId}`, props, tokenConfig(getState))
    .then((res) => {
      if (!id)
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: res.data.user,
        });
      else
        dispatch({
          type: UPDATE_USER_BY_ADMIN_SUCCESS,
          payload: res.data.user,
        });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_USER_FAILURE, USER_EDIT_FORM_ERRORS);
    });
};

export const updateMailing = (mailing) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_MAILING,
    payload: mailing,
  });
};

export const signOutUser =
  (redirectParam = null) =>
  (dispatch, getState) => {
    const { token } = getState().auth;
    vod
      .post(
        `/api/auth/logout`,
        { token },
        { withCredentials: true, ...tokenConfig(getState) }
      )
      .then((res) => {
        dispatch({ type: UNAUTH_USER });
        localStorage.clear();
        if (res.data.logoutUrl) window.location.href = res.data.logoutUrl;
      })
      .catch((err) => {})
      .then(() => {
        dispatch({ type: UNAUTH_USER });
        localStorage.clear();
        history.push(
          `/logowanie${
            redirectParam && typeof redirectParam === "string"
              ? `?${redirectParam}=true`
              : ""
          }`
        );
      });
  };

export const deleteUser = (id) => (dispatch, getState) => {
  const userId = id ? id : getState().auth.user.id;
  vod
    .delete(`/api/users/${userId}`, tokenConfig(getState))
    .then((res) => {
      if (userId === getState().auth.user.id) dispatch(signOutUser());
      else {
        dispatch({
          type: DELETE_ACCOUNT_SUCCESS,
          payload: id,
        });
        history.push("/teapp-admin");
      }
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_ACCOUNT_FAILURE);
    });
};

export const clearSignIn = () => (dispatch) => {
  dispatch({ type: CLEAR_SIGNIN });
};
export const clearSignUp = () => (dispatch) => {
  dispatch({ type: CLEAR_SIGNUP });
};
export const clearUpdateUserState = () => (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_USER });
};
