import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card, Row, Col, Popconfirm, Button, Tooltip, Tag } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  VideoCameraOutlined,
  TranslationOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import { renderPreview } from "../../../../../helpers/renderPreview";
import { getDatetime } from "../../../../../helpers/productUtilities";

class VideoList extends React.Component {
  render() {
    const videos = this.props.videos;

    return (
      <Card
        style={{ marginBottom: "30px" }}
        title="Lista wideo"
        extra={
          <Button type="primary" onClick={this.props.addVideoClick}>
            Dodaj wideo
          </Button>
        }
      >
        <Row gutter={[16, 16]} className="videos-list">
          {videos.map((video) => {
            let datetime = getDatetime(
              moment(video.added_at).format("YYYY-MM-DD"),
              moment(video.added_at).format("HH:mm:ss")
            );
            if (video.available_from)
              datetime = getDatetime(
                moment(video.available_from).format("YYYY-MM-DD"),
                moment(video.available_from).format("HH:mm:ss")
              );

            return (
              <Col xs={24} md={12} lg={8} xl={6} key={video.id}>
                <Card
                  className={`videoCard ${
                    video.visible === 1 ? "visible" : "hidden"
                  }`}
                  actions={[
                    <Tooltip title="Przejdź na stronę wideo">
                      <Link
                        to={`/wideo/${video.slug || video.id}`}
                        style={{ color: "black" }}
                      >
                        <EyeOutlined key="see" />
                      </Link>
                    </Tooltip>,
                    <Tooltip title="Edytuj">
                      <EditOutlined
                        key="edit"
                        onClick={() => this.props.editVideoClick(video.id)}
                      />
                    </Tooltip>,
                    <Tooltip title="Zarządzaj tłumaczeniami">
                      <TranslationOutlined
                        key="translations"
                        onClick={() =>
                          this.props.manageTranslationsClick(video.id)
                        }
                      />
                    </Tooltip>,
                    <Tooltip title="Usuń">
                      <Popconfirm
                        title={`Czy na pewno chcesz usunąć wideo: ${video.title}?`}
                        onConfirm={() => this.props.deleteVideoClick(video.id)}
                        okText="Tak"
                        cancelText="Nie"
                      >
                        <DeleteOutlined key="delete" />
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                >
                  <Meta
                    avatar={<VideoCameraOutlined />}
                    title={`${video.title}`}
                  />
                  <div className="video-details">
                    {video.end_date &&
                    moment(video.end_date).isBefore(moment()) ? (
                      <Tag color="warning">Zakończony</Tag>
                    ) : video.visible ? (
                      <Tag color="success">Widoczny w sekcji VOD</Tag>
                    ) : (
                      <Tag color="error">Niewidoczny w sekcji VOD</Tag>
                    )}
                    {!video.slug ? (
                      <Tooltip title="Proszę przejść do edycji wideo i zaktualizować dane. Odnośnik zostanie wygenerowany automatycznie na podstawie tytułu, ale można go zmodyfikować.">
                        <Tag color="error">Niezdefiniowany odnośnik</Tag>
                      </Tooltip>
                    ) : null}
                    {video.auth_required ? (
                      <Tag color="warning">Tylko dla zalogowanych</Tag>
                    ) : null}
                    {video.mailing_free ? (
                      Number.parseFloat(video.video_price) === 0 ? (
                        <Tag color="warning">Tylko newsletter</Tag>
                      ) : (
                        <Tag color="warning">Darmowy dla newslettera</Tag>
                      )
                    ) : null}
                    <br />
                    <br />
                    <b>Dodano:</b>{" "}
                    {moment(video.added_at).format("H:mm DD.MM.YYYY")}
                    <br />
                    <b>Cena:</b> {video.video_price} zł
                    <br />
                    <b>Dostępne od:</b> {datetime.format("H:mm DD.MM.YYYY")}
                    <br />
                    {video.end_date ? (
                      <>
                        <b>Dostępne do:</b>{" "}
                        {getDatetime(
                          moment(video.end_date).format("YYYY-MM-DD"),
                          moment(video.end_date).format("HH:mm:ss")
                        ).format("H:mm DD.MM.YYYY")}
                        <br />
                      </>
                    ) : null}
                    <b>Ograniczenie wiekowe:</b>{" "}
                    {video.age_restriction
                      ? `+${video.age_restriction}`
                      : `brak`}
                    <br />
                    <b>Czas trwania:</b>{" "}
                    {video.duration
                      ? `${video.duration} min`
                      : `niezdefiniowano`}
                  </div>
                  {renderPreview(video.video_link)}
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
    );
  }
}

export default VideoList;
