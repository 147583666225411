import { Button } from "antd";
import React, { Component } from "react";
import "./PacketBlock.scss";
import { withTranslation } from "react-i18next";
import tc from "../../../helpers/translateContent";
import { Link } from "react-router-dom";

class PacketBlock extends Component {
  render() {
    return (
      <>
        {this.props.packets.map((p) => {
          const { mobile_image, tablet_image, desktop_image } = p;
          return (
            <>
              {mobile_image ? (
                <div
                  className={`packet-block packet-block-mobile ${
                    !tablet_image ? "packet-block-tablet" : ""
                  } ${
                    !tablet_image && !desktop_image
                      ? "packet-block-desktop"
                      : ""
                  }`}
                >
                  <img
                    src={`${mobile_image}`}
                    alt={`Baner - kup prezent: ${p.title}`}
                  />
                </div>
              ) : null}
              {tablet_image ? (
                <div
                  className={`packet-block packet-block-tablet ${
                    !desktop_image ? "packet-block-desktop" : ""
                  }`}
                >
                  <img
                    src={`${tablet_image}`}
                    alt={`Baner - kup prezent: ${p.title}`}
                  />
                </div>
              ) : null}
              {desktop_image ? (
                <div className="packet-block packet-block-desktop">
                  <img
                    src={`${desktop_image}`}
                    alt={`Baner - kup prezent: ${p.title}`}
                  />
                </div>
              ) : null}
              <div className={`packet-card`}>
                <div className="packet-title">
                  {tc(p.title, "title", p.translations)}
                </div>
                <div
                  className="packet-desc"
                  dangerouslySetInnerHTML={{
                    __html: tc(p.description, "description", p.translations),
                  }}
                />
                <div className="packet-price">{p.price} zł</div>
                <div className="packet-payment-status">
                  <Link to={`/platnosc/pakiet/${p.id}`}>
                    <Button>{this.props.t("dashboard.buy_bundle")}</Button>
                  </Link>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

export default withTranslation()(PacketBlock);
