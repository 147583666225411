import React from "react";
import Wrapper from "../../wrappers/Wrapper";
import "./StaticPage.scss";
import { withTranslation } from "react-i18next";
import qs from "query-string";
import { notification } from "antd";
import store from "../../../store";
import history from "../../../history";
import vod from "../../../apis/vod";

class StaticPage extends React.Component {
  displayError = () => {
    history.push("/logowanie?tlError=true");
  };
  componentDidMount() {
    const { code, state, error } = qs.parse(this.props.location.search);

    if (code)
      vod
        .post(
          "/api/auth/tl",
          {
            code,
            state,
          },
          { withCredentials: true }
        )
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data));
          store.dispatch({ type: "AUTH_USER", payload: res.data });
          notification.success({
            message: res.data.registered
              ? this.props.t(
                  "Zalogowałeś/aś się po raz pierwszy na platformę za pomocą konta teapp.link!"
                )
              : res.data.sync
              ? this.props.t(
                  "Twoje konto VOD zostało zschynronizowane z kontem teapp.link. Od teraz możesz logować się na to konto za pomocą platformy teapp.link."
                )
              : this.props.t(
                  "auth.success",
                  "Zalogowałeś/aś się za pomocą konta teapp.link!"
                ),
          });
          history.push("/");
        })
        .catch((err) => {
          if (err.response.data.error === "user_already_registered") {
            history.push("/logowanie?tlAlreadyRegistered=true");
          } else if (err.response.data.statusCode === 401) {
            history.push("/logowanie?userBlocked=true");
          } else this.displayError();
        });
    else if (error) {
      this.displayError();
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Wrapper authLayout={false}>
        <h1>{t("auth.processing")}</h1>
        <h2>{t("auth.wait")}</h2>
      </Wrapper>
    );
  }
}

export default withTranslation()(StaticPage);
