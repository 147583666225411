// SIGNUP
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const CLEAR_SIGNUP = "CLEAR_SIGNUP";
export const SIGNUP_FORM_ERRORS = "SIGNUP_FORM_ERRORS";

// RESEND EMAIL
export const VERIFY_RESEND_SUCCESS = "VERIFY_RESEND_SUCCESS";
export const VERIFY_RESEND_FAILURE = "VERIFY_RESEND_FAILURE";
export const VERIFY_RESEND_LOADING = "VERIFY_RESEND_LOADING";

// SIGNIN and SIGNOUT
export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const AUTH_USER = "AUTH_USER";
export const AUTH_ADMIN = "AUTH_ADMIN";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const SIGNIN_FORM_ERRORS = "SIGNIN_FORM_ERRORS";
export const UNAUTH_USER = "UNAUTH_USER";
export const CLEAR_SIGNIN = "CLEAR_SIGNIN";

// ACCOUNT VERIFICATION
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_RESET_PASSWORD_SUCCESS = "VERIFY_RESET_PASSWORD_SUCCESS";
export const VERIFY_RESET_PASSWORD_FAILURE = "VERIFY_RESET_PASSWORD_FAILURE";

// RESET PASSWORD
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FORM_ERROR = "RESET_PASSWORD_FORM_ERROR";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// SET NEW PASSWORD
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";

// ERROR PAGE
export const SET_ERROR_PAGE = "SET_ERROR_PAGE";

// VIDEOS
export const VIDEOS_LOADING = "GET_VIDEOS_LOADING";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAILURE = "GET_VIDEOS_FAILURE";
export const GET_HIDDEN_VIDEOS_SUCCESS = "GET_HIDDEN_VIDEOS_SUCCESS";
export const GET_HIDDEN_VIDEOS_FAILURE = "GET_HIDDEN_VIDEOS_FAILURE";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_FAILURE = "GET_VIDEO_FAILURE";

export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const ADD_VIDEO_FAILURE = "ADD_VIDEO_FAILURE";

export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE";

export const VIDEO_FORM_ERRORS = "VIDEO_FORM_ERRORS";
export const CLEAR_VIDEO_PAYMENT_ERROR = "CLEAR_EVENT_PAYMENT_ERROR";

// EVENTS
export const EVENTS_LOADING = "GET_EVENTS_LOADING";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
export const GET_HIDDEN_EVENTS_SUCCESS = "GET_HIDDEN_EVENTS_SUCCESS";
export const GET_HIDDEN_EVENTS_FAILURE = "GET_HIDDEN_EVENTS_FAILURE";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";

export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const EVENT_FORM_ERRORS = "EVENT_FORM_ERRORS";
export const CLEAR_EVENT_PAYMENT_ERROR = "CLEAR_EVENT_PAYMENT_ERROR";

// USER ACCOUNT
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const USER_EDIT_FORM_ERRORS = "USER_EDIT_FORM_ERRORS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_BY_ADMIN_SUCCESS = "UPDATE_USER_BY_ADMIN_SUCCESS";
export const CLEAR_UPDATE_USER = "CLEAR_UPDATE_USER";
export const UPDATE_MAILING = "UPDATE_MAILING";

// EVENT PAYMENTS
export const BUY_TICKET_LOADING = "BUY_TICKET_LOADING";
export const BUY_TICKET_SUCCESS = "BUY_TICKET_SUCCESS";
export const BUY_TICKET_FAILURE = "BUY_TICKET_FAILURE";

export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";
export const ASSIGN_ACCESS_SUCCESS = "ASSIGN_ACCESS_SUCCESS";
export const ASSIGN_ACCESS_FAILURE = "ASSIGN_ACCESS_FAILURE";
export const DELETE_EVENT_PAYMENT_SUCCESS = "DELETE_EVENT_PAYMENT_SUCCESS";
export const DELETE_EVENT_PAYMENT_FAILURE = "DELETE_EVENT_PAYMENT_FAILURE";

// VIDEO PAYMENTS
export const BUY_VIDEO_TICKET_LOADING = "BUY_VIDEO_TICKET_LOADING";
export const BUY_VIDEO_TICKET_SUCCESS = "BUY_VIDEO_TICKET_SUCCESS";
export const BUY_VIDEO_TICKET_FAILURE = "BUY_VIDEO_TICKET_FAILURE";

export const GET_VIDEO_ORDER_SUCCESS = "GET_VIDEO_ORDER_SUCCESS";
export const GET_VIDEO_ORDER_FAILURE = "GET_VIDEO_ORDER_FAILURE";
export const GET_VIDEO_ORDERS_SUCCESS = "GET_VIDEO_ORDERS_SUCCESS";
export const GET_VIDEO_ORDERS_FAILURE = "GET_VIDEO_ORDERS_FAILURE";
export const ASSIGN_VIDEO_ACCESS_SUCCESS = "ASSIGN_VIDEO_ACCESS_SUCCESS";
export const ASSIGN_VIDEO_ACCESS_FAILURE = "ASSIGN_VIDEO_ACCESS_FAILURE";
export const DELETE_VIDEO_PAYMENT_SUCCESS = "DELETE_VIDEO_PAYMENT_SUCCESS";
export const DELETE_VIDEO_PAYMENT_FAILURE = "DELETE_VIDEO_PAYMENT_FAILURE";

// VIMEO ACTIONS
export const ADD_VIMEO_VIDEO_SUCCESS = "ADD_VIMEO_VIDEO_SUCCESS";
export const DELETE_VIMEO_VIDEO_SUCCESS = "DELETE_VIMEO_VIDEO_SUCCESS";
export const ADD_VIMEO_VIDEO_FAILURE = "ADD_VIMEO_VIDEO_FAILURE";
export const DELETE_VIMEO_VIDEO_FAILURE = "DELETE_VIMEO_VIDEO_FAILURE";
export const GET_VIMEO_VIDEO_SUCCESS = "GET_VIMEO_VIDEO_SUCCESS";
export const GET_VIMEO_VIDEO_FAILURE = "GET_VIMEO_VIDEO_FAILURE";
export const GET_VIMEO_VIDEOS_SUCCESS = "GET_VIMEO_VIDEOS_SUCCESS";
export const GET_VIMEO_VIDEOS_FAILURE = "GET_VIMEO_VIDEOS_FAILURE";
export const FORM_VIMEO_VIDEO_FAILURE = "FORM_VIMEO_VIDEO_FAILURE";
export const UPDATE_VIMEO_VIDEO_SUCCESS = "UPDATE_VIMEO_VIDEO_SUCCESS";
export const UPDATE_VIMEO_VIDEO_FAILURE = "UPDATE_VIMEO_VIDEO_FAILURE";

// CATEGORIES ACTIONS
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const FORM_CATEGORIES_FAILURE = "FORM_CATEGORIES_FAILURE";

// BANS ACTIONS
export const BANS_LOADING = "BANS_LOADING";
export const ADD_BAN_SUCCESS = "ADD_BAN_SUCCESS";
export const ADD_BAN_FAILURE = "ADD_BAN_FAILURE";
export const DELETE_BAN_SUCCESS = "DELETE_BAN_SUCCESS";
export const DELETE_BAN_FAILURE = "DELETE_BAN_FAILURE";
export const GET_BANS_SUCCESS = "GET_BANS_SUCCESS";
export const GET_BANS_FAILURE = "GET_BANS_FAILURE";
export const FORM_BANS_FAILURE = "FORM_BANS_FAILURE";

// SLIDER ACTIONS
export const SLIDES_LOADING = "GET_SLIDES_LOADING";
export const GET_SLIDES_SUCCESS = "GET_SLIDES_SUCCESS";
export const GET_SLIDES_FAILURE = "GET_SLIDES_FAILURE";
export const GET_SLIDE_SUCCESS = "GET_SLIDE_SUCCESS";
export const GET_SLIDE_FAILURE = "GET_SLIDE_FAILURE";
export const DELETE_SLIDE_SUCCESS = "DELETE_SLIDE_SUCCESS";
export const DELETE_SLIDE_FAILURE = "DELETE_SLIDE_FAILURE";
export const ADD_SLIDE_SUCCESS = "ADD_SLIDE_SUCCESS";
export const ADD_SLIDE_FAILURE = "ADD_SLIDE_FAILURE";
export const UPDATE_SLIDE_SUCCESS = "UPDATE_SLIDE_SUCCESS";
export const UPDATE_SLIDE_FAILURE = "UPDATE_SLIDE_FAILURE";
export const UPDATE_WEIGHT_SUCCESS = "UPDATE_WEIGHT_SUCCESS";
export const UPDATE_WEIGHT_FAILURE = "UPDATE_WEIGHT_FAILURE";
export const SLIDE_FORM_ERRORS = "SLIDE_FORM_ERRORS";

// CONFIG ACTIONS
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const CONFIG_FORM_ERRORS = "CONFIG_FORM_ERRORS";

// ACCOUNT DELETION
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

// CODES
export const CODES_LOADING = "GET_CODES_LOADING";
export const GET_CODES_SUCCESS = "GET_CODES_SUCCESS";
export const GET_CODES_FAILURE = "GET_CODES_FAILURE";
export const GET_CODE_SUCCESS = "GET_CODE_SUCCESS";
export const GET_CODE_FAILURE = "GET_CODE_FAILURE";
export const DELETE_CODE_SUCCESS = "DELETE_CODE_SUCCESS";
export const DELETE_CODE_FAILURE = "DELETE_CODE_FAILURE";
export const RENDER_CODES_SUCCESS = "RENDER_CODES_SUCCESS";
export const RENDER_CODES_FAILURE = "RENDER_CODES_FAILURE";
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS";
export const UPDATE_CODE_FAILURE = "UPDATE_CODE_FAILURE";
export const CODE_FORM_ERRORS = "CODE_FORM_ERRORS";
// - users
export const USER_CODE_FORM_ERRORS = "USER_CODE_FORM_ERRORS";
export const ACTIVATE_CODE_SUCCESS = "ACTIVATE_CODE_SUCCESS";
export const ACTIVATE_CODE_FAILURE = "ACTIVATE_CODE_FAILURE";
export const DELETE_USER_CODE_SUCCESS = "DELETE_USER_CODE_SUCCESS";
export const DELETE_USER_CODE_FAILURE = "DELETE_USER_CODE_FAILURE";
export const GET_USER_CODES_SUCCESS = "GET_USER_CODES_SUCCESS";
export const GET_USER_CODES_FAILURE = "GET_USER_CODES_FAILURE";
export const GET_USER_CODE_SUCCESS = "GET_USER_CODE_SUCCESS";
export const GET_USER_CODE_FAILURE = "GET_USER_CODE_FAILURE";

// GALLERIES
export const GALLERIES_LOADING = "GALLERIES_LOADING";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_FAILURE = "CREATE_GALLERY_FAILURE";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_FAILURE = "DELETE_GALLERY_FAILURE";
export const GET_GALLERIES_SUCCESS = "GET_GALLERIES_SUCCESS";
export const GET_GALLERIES_FAILURE = "GET_GALLERIES_FAILURE";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";
export const DELETE_GALLERY_IMAGE_SUCCESS = "DELETE_GALLERY_IMAGE_SUCCESS";
export const DELETE_GALLERY_IMAGE_FAILURE = "DELETE_GALLERY_IMAGE_FAILURE";
export const ADD_GALLERY_IMAGE_SUCCESS = "ADD_GALLERY_IMAGE_SUCCESS";
export const ADD_GALLERY_IMAGE_FAILURE = "ADD_GALLERY_IMAGE_FAILURE";
export const GALLERY_FORM_ERRORS = "GALLERY_FORM_ERRORS";
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_FAILURE = "UPDATE_GALLERY_FAILURE";

// INVOICES ACTIONS
export const INVOICES_LOADING = "GET_INVOICES_LOADING";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "UPDATE_INVOICE_FAILURE";
export const INVOICE_FORM_ERRORS = "INVOICE_FORM_ERRORS";

//gifts
export const BUY_GIFT_LOADING = "BUY_GIFT_LOADING";
export const BUY_GIFT_SUCCESS = "BUY_GIFT_SUCCESS";
export const BUY_GIFT_FAILURE = "BUY_GIFT_FAILURE";
export const GET_GIFT_ORDER_SUCCESS = "GET_GIFT_ORDER_SUCCESS";
export const GET_GIFT_ORDER_FAILURE = "GET_GIFT_ORDER_FAILURE";
export const GET_GIFT_ORDERS_SUCCESS = "GET_GIFT_ORDERS_SUCCESS";
export const GET_GIFT_ORDERS_FAILURE = "GET_GIFT_ORDERS_FAILURE";
export const CLEAR_GIFT_PAYMENT_ERROR = "CLEAR_GIFT_PAYMENT_ERROR";
export const GET_GIFT_CONFIG_SUCCESS = "GET_GIFT_CONFIG_SUCCESS";
export const GET_GIFT_CONFIG_FAILURE = "GET_GIFT_CONFIG_FAILURE";
export const UPDATE_GIFT_CONFIG_SUCCESS = "UPDATE_GIFT_CONFIG_SUCCESS";
export const UPDATE_GIFT_CONFIG_FAILURE = "UPDATE_GIFT_CONFIG_FAILURE";
export const GIFT_CONFIG_LOADING = "GIFT_CONFIG_LOADING";
export const GIFT_FORM_ERRORS = "GIFT_FORM_ERRORS";
export const CLEAR_GIFT_CONFIG_STATUS = "CLEAR_GIFT_CONFIG_STATUS";

// GROUP TICKETS
export const BUY_GROUP_TICKET_LOADING = "BUY_GROUP_TICKET_LOADING";
export const BUY_GROUP_TICKET_SUCCESS = "BUY_GROUP_TICKET_SUCCESS";
export const BUY_GROUP_TICKET_FAILURE = "BUY_GROUP_TICKET_FAILURE";
export const GET_GROUP_TICKET_ORDER_SUCCESS = "GET_GROUP_TICKET_ORDER_SUCCESS";
export const GET_GROUP_TICKET_ORDER_FAILURE = "GET_GROUP_TICKET_ORDER_FAILURE";
export const GET_GROUP_TICKET_ORDERS_SUCCESS =
  "GET_GROUP_TICKET_ORDERS_SUCCESS";
export const GET_GROUP_TICKET_ORDERS_FAILURE =
  "GET_GROUP_TICKET_ORDERS_FAILURE";
export const CLEAR_GROUP_TICKET_PAYMENT_ERROR =
  "CLEAR_GROUP_TICKET_PAYMENT_ERROR";
export const GET_GROUP_TICKET_CONFIG_SUCCESS =
  "GET_GROUP_TICKET_CONFIG_SUCCESS";
export const GET_GROUP_TICKET_CONFIG_FAILURE =
  "GET_GROUP_TICKET_CONFIG_FAILURE";
export const UPDATE_GROUP_TICKET_CONFIG_SUCCESS =
  "UPDATE_GROUP_TICKET_CONFIG_SUCCESS";
export const UPDATE_GROUP_TICKET_CONFIG_FAILURE =
  "UPDATE_GROUP_TICKET_CONFIG_FAILURE";
export const GROUP_TICKET_CONFIG_LOADING = "GROUP_TICKET_CONFIG_LOADING";
export const GROUP_TICKET_FORM_ERRORS = "GROUP_TICKET_FORM_ERRORS";
export const CLEAR_GROUP_TICKET_CONFIG_STATUS =
  "CLEAR_GROUP_TICKET_CONFIG_STATUS";

// PACKETS
export const PACKETS_LOADING = "GET_PACKETS_LOADING";
export const GET_PACKETS_SUCCESS = "GET_PACKETS_SUCCESS";
export const GET_PACKETS_FAILURE = "GET_PACKETS_FAILURE";
export const GET_PACKET_SUCCESS = "GET_PACKET_SUCCESS";
export const GET_PACKET_FAILURE = "GET_PACKET_FAILURE";

export const DELETE_PACKET_SUCCESS = "DELETE_PACKET_SUCCESS";
export const DELETE_PACKET_FAILURE = "DELETE_PACKET_FAILURE";

export const ADD_PACKET_SUCCESS = "ADD_PACKET_SUCCESS";
export const ADD_PACKET_FAILURE = "ADD_PACKET_FAILURE";

export const UPDATE_PACKET_SUCCESS = "UPDATE_PACKET_SUCCESS";
export const UPDATE_PACKET_FAILURE = "UPDATE_PACKET_FAILURE";

export const PACKET_FORM_ERRORS = "PACKET_FORM_ERRORS";
export const CLEAR_PACKET_PAYMENT_ERROR = "CLEAR_PACKET_PAYMENT_ERROR";

// PACKET PAYMENTS
export const BUY_PACKET_TICKET_LOADING = "BUY_PACKET_TICKET_LOADING";
export const BUY_PACKET_TICKET_SUCCESS = "BUY_PACKET_TICKET_SUCCESS";
export const BUY_PACKET_TICKET_FAILURE = "BUY_PACKET_TICKET_FAILURE";

export const GET_PACKET_ORDER_SUCCESS = "GET_PACKET_ORDER_SUCCESS";
export const GET_PACKET_ORDER_FAILURE = "GET_PACKET_ORDER_FAILURE";
export const GET_PACKET_ORDERS_SUCCESS = "GET_PACKET_ORDERS_SUCCESS";
export const GET_PACKET_ORDERS_FAILURE = "GET_PACKET_ORDERS_FAILURE";
export const DELETE_PACKET_PAYMENT_SUCCESS = "DELETE_PACKET_PAYMENT_SUCCESS";
export const DELETE_PACKET_PAYMENT_FAILURE = "DELETE_PACKET_PAYMENT_FAILURE";

// FREE CODES AFTER SIGNUP
export const FREE_CODES_CONFIG_LOADING = "FREE_CODES_CONFIG_LOADING";
export const GET_FREE_CODES_CONFIG_SUCCESS = "GET_FREE_CODES_CONFIG_SUCCESS";
export const GET_FREE_CODES_CONFIG_FAILURE = "GET_FREE_CODES_CONFIG_FAILURE";
export const UPDATE_FREE_CODES_CONFIG_SUCCESS =
  "UPDATE_FREE_CODES_CONFIG_SUCCESS";
export const UPDATE_FREE_CODES_CONFIG_FAILURE =
  "UPDATE_FREE_CODES_CONFIG_FAILURE";
export const FREE_CODES_FORM_ERRORS = "FREE_CODES_FORM_ERRORS";
export const CLEAR_FREE_CODES_CONFIG_STATUS = "CLEAR_FREE_CODES_CONFIG_STATUS";
