import {
  FREE_CODES_CONFIG_LOADING,
  UPDATE_FREE_CODES_CONFIG_SUCCESS,
  UPDATE_FREE_CODES_CONFIG_FAILURE,
  CLEAR_FREE_CODES_CONFIG_STATUS,
  GET_FREE_CODES_CONFIG_SUCCESS,
  GET_FREE_CODES_CONFIG_FAILURE,
} from "../actions/_types";

const initialState = {
  configLoading: true,
  updateSuccess: false,
  config: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FREE_CODES_CONFIG_SUCCESS:
      return {
        ...state,
        configLoading: false,
        error: undefined,
        getSuccess: true,
        config: action.payload,
      };
    case GET_FREE_CODES_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        getSuccess: false,
        configLoading: false,
      };
    case UPDATE_FREE_CODES_CONFIG_SUCCESS:
      return {
        ...state,
        error: undefined,
        config: action.payload,
        updateSuccess: true,
      };
    case UPDATE_FREE_CODES_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        updateSuccess: false,
      };
    case CLEAR_FREE_CODES_CONFIG_STATUS:
      return {
        ...state,
        updateSuccess: false,
        error: false,
      };
    case FREE_CODES_CONFIG_LOADING:
      return {
        ...state,
        configLoading: true,
      };
    default:
      return state;
  }
}
