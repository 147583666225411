import React from "react";
import moment from "moment";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import Node from "./Node";
import { withTranslation } from "react-i18next";
import Wrapper from "../../wrappers/Wrapper";
import history from "../../../history";
import tc from "../../../helpers/translateContent";
import vod from "../../../apis/vod";
import { getDatetime } from "../../../helpers/productUtilities";
import TagManager from "react-gtm-module";
import _ from "lodash";
import { googleTagManager } from "../../../theme-variables";
import Transmission from "./Transmission";
import AdvertisementBlock from "./AdvertisementBlock";

class NoAuthVideoPage extends React.Component {
  state = { video: null, loading: true, transmission: false };
  fetchVideo = () => {
    vod
      .get("/external_api/video/" + this.videoId)
      .then((res) => {
        this.setState({ video: res.data.video, loading: false });
      })
      .catch((err) => console.error(err));
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      googleTagManager &&
      prevState.video !== this.state.video &&
      !!this.state.video &&
      !this.state.loading
    ) {
      const dataLayer = {
        video: "productView",
        page_type: "video",
        page: _.pick(
          this.state.video,
          "id",
          "title",
          "description",
          "subtitle",
          "video_price",
          "additional_desc",
          "age_restriction",
          "duration",
          "available_from",
          "created_at"
        ),
      };
      TagManager.dataLayer({ ...dataLayer, authenticated: false });
      const { title, id, video_price } = this.state.video;
      TagManager.dataLayer({ ecommerce: null });
      TagManager.dataLayer({
        ecommerce: {
          currencyCode: "PLN",
          impressions: [
            {
              name: title,
              id: id,
              price: video_price,
              category: "video",
            },
          ],
        },
      });
    }
  }
  componentDidMount() {
    this.videoId = this.props.match.params.id;
    if (this.props.autenticated) history.push(`/wideo/${this.videoId}`);
    this.fetchVideo(this.videoId);
  }
  render() {
    const { loading, video } = this.state;
    const { t } = this.props;
    if (!loading && video) {
      const {
        title,
        available_from,
        description,
        video_price,
        duration,
        age_restriction,
        teaser_link,
        gallery,
        available,
        image_link,
        translations,
        additional_desc,
        end_date,
        video_link,
        autodesc_video,
        auth_required,
        accessibility_video,
        mailing_free,
      } = video;
      let datetime;
      const { transmission } = this.state;
      if (available_from)
        datetime = getDatetime(
          moment(available_from).format("YYYY-MM-DD"),
          moment(available_from).format("HH:mm:ss")
        );
      const time = datetime ? datetime.format("H:mm") : undefined;
      const date = datetime ? datetime.format("DD.MM.YYYY") : undefined;
      let paymentButtonProps = null;
      if (!auth_required && available && Number.parseFloat(video_price) === 0)
        paymentButtonProps = null;
      else
        paymentButtonProps = {
          color: "white",
          text:
            Number.parseFloat(video_price) === 0
              ? t("videodesc.login")
              : t("videodesc.buy"),
          disabled: false,
          onClick: () => {
            history.push(
              `/logowanie?redirect=/wideo/${this.props.match.params.id}`
            );
          },
        };

      let countdownText = t("videodesc.video_from");
      let countdownDate =
        available_from && moment(available_from).isAfter(moment())
          ? moment(available_from)
          : undefined;

      if (
        available &&
        end_date &&
        end_date !== "0000-00-00" &&
        moment().isBefore(moment(end_date))
      ) {
        countdownText = t("videodesc.video_to");
        countdownDate = moment(end_date);
        let diff = moment.duration(countdownDate.diff(moment()));
        if (diff.asHours() > 24) countdownText = t("videodesc.video_to_2");
      }
      if (!transmission)
        return (
          <Wrapper>
            <Node
              title={tc(title, "title", translations)}
              productType="video"
              time={time}
              date={date}
              countdownText={countdownText}
              countdownDate={countdownDate}
              forceCountdown={countdownDate ? true : false}
              description={tc(description, "description", translations)}
              additionalDesc={tc(
                additional_desc,
                "additional_desc",
                translations
              )}
              paymentButton={paymentButtonProps}
              price={{
                price: video_price,
              }}
              duration={duration}
              ageRestriction={age_restriction}
              teaserLink={tc(teaser_link, "teaser_link", translations)}
              available={available}
              availableText={t("videodesc.video_on")}
              mainImage={image_link}
              gallery={gallery}
              authRequired={auth_required}
              mailingFree={mailing_free}
              access={
                !auth_required &&
                !mailing_free &&
                Number.parseFloat(video_price) === 0
              }
              turnOnTransmission={() => {
                this.fetchVideo();
                setTimeout(() => {
                  this.setState({ transmission: true });
                }, 500);
              }}
            />
          </Wrapper>
        );
      else
        return (
          <Wrapper>
            <Transmission
              title={tc(title, "title", translations)}
              queryParams={this.props.location.search}
              time={time}
              date={date}
              price={video_price}
              id={this.props.match.params.id}
              urlVideo={tc(video_link, "video_link", translations)}
              access={!auth_required && Number.parseFloat(video_price) === 0}
              urlAccessibility={tc(
                accessibility_video,
                "accessibility_video",
                translations
              )}
              urlAudiodesc={tc(autodesc_video, "autodesc_video", translations)}
              userToken={this.props.token}
              signOutUser={this.props.signOutUser}
              stopTransmission={() => {
                this.setState({ transmission: false });
              }}
            />
            <AdvertisementBlock isPaid={Number.parseFloat(video_price) !== 0} />
          </Wrapper>
        );
    } else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.autenticated,
  };
};

export default connect(mapStateToProps)(withTranslation()(NoAuthVideoPage));
