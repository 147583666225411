import React from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Drawer, Skeleton } from "antd";
import {
  getVideos,
  deleteVideo,
  addVideo,
  updateVideo,
} from "../../../../actions/videosActions";
import { getVideos as getVimeoVideos } from "../../../../actions/vimeoActions";
import { getCategories } from "../../../../actions/categoriesActions";
import { getGalleries } from "../../../../actions/galleriesActions";
import VideoForm from "./forms/VideoForm";
import VideoList from "./lists/VideoList";
import VimeoVideoList from "./lists/VimeoVideoList";
import VideosTranslationsModal from "./others/VideosTranslationsModal";
import "../managers.scss";
import slugify from "../../../../helpers/slugify";

class VideosManager extends React.Component {
  state = {
    visible: false,
    mode: "add",
    chosenVideo: null,
    success: true,
    showTranslations: false,
  };
  componentDidMount() {
    this.props.getVimeoVideos();
    this.props.getVideos();
    this.props.getCategories();
    this.props.getGalleries();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success && prevState.success) return { visible: false };
    return null;
  }
  handleAdd = (formValues) => {
    this.setState({ success: true });
    this.props.addVideo(formValues);
  };
  handleEdit = (formValues) => {
    this.setState({ success: true });
    this.props.updateVideo(formValues, this.state.chosenVideo);
  };
  addVideoClick = () => {
    this.setState({
      visible: true,
      mode: "add",
      chosenVideo: null,
      success: false,
    });
  };
  editVideoClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenVideo: id,
      success: false,
    });
  };
  manageTranslationsClick = (id) => {
    this.setState({
      chosenVideo: id,
      showTranslations: true,
    });
  };
  deleteVideoClick = (id) => {
    this.props.deleteVideo(id);
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const videos = this.props.videos;
    const loading = false;
    let initialValues = this.state.chosenVideo
      ? _.pick(
          videos.filter((v) => v.id === this.state.chosenVideo)[0],
          "title",
          "subtitle",
          "teaser_link",
          "video_link",
          "thumb_link",
          "accessibility_video",
          "autodesc_video",
          "visible",
          "description",
          "additional_desc",
          "image_link",
          "age_restriction",
          "duration",
          "gallery_id",
          "weight",
          "available_from",
          "video_price",
          "restricted_access",
          "dashboard_hidden",
          "end_date",
          "tickets_expiration_time",
          "slug",
          "share_teatrlink",
          "auth_required",
          "mailing_free"
        )
      : {
          visible: 1,
          weight: 0,
          dashboard_hidden: 0,
          tickets_expiration_time: "undefined",
          share_teatrlink: 0,
          auth_required: true,
          mailing_free: false,
        };
    if (!initialValues.slug && initialValues.title)
      initialValues.slug = slugify(initialValues.title).substr(0, 40);
    if (
      !initialValues.tickets_expiration_time &&
      initialValues.tickets_expiration_time !== 0
    )
      initialValues.tickets_expiration_time = "undefined";
    if (initialValues.description)
      initialValues.description = initialValues.description.replace(
        /<br \/>/gi,
        "\n"
      );
    if (initialValues.additional_desc)
      initialValues.additional_desc = initialValues.additional_desc.replace(
        /<br \/>/gi,
        "\n"
      );
    initialValues.visible = initialValues.visible == 0 ? 0 : 1;
    initialValues.auth_required = initialValues.auth_required == 0 ? 0 : 1;
    initialValues.mailing_free = initialValues.mailing_free == 0 ? 0 : 1;
    if (initialValues.available_from)
      initialValues.available_from = moment(initialValues.available_from);
    if (initialValues.end_date)
      initialValues.end_date = moment(initialValues.end_date);

    const initialCategories = this.state.chosenVideo
      ? _.pick(
          videos.filter((v) => v.id === this.state.chosenVideo)[0],
          "categories"
        ).categories
      : [];
    if (!loading)
      return (
        <>
          <VimeoVideoList />
          <VideoList
            videos={videos}
            addVideoClick={this.addVideoClick}
            editVideoClick={this.editVideoClick}
            deleteVideoClick={this.deleteVideoClick}
            manageTranslationsClick={this.manageTranslationsClick}
          />
          <Drawer
            className="formDrawer"
            width="90%"
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
            maskClosable={false}
          >
            <h2>
              {this.state.mode === "add" ? "Dodaj wideo" : "Edytuj wideo"}
            </h2>
            {this.state.visible ? (
              <VideoForm
                mode={this.state.mode}
                onSubmit={
                  this.state.mode === "add" ? this.handleAdd : this.handleEdit
                }
                token={this.props.token}
                galleries={this.props.galleries}
                videoId={this.state.chosenVideo}
                vimeoVideos={this.props.vimeoVideos}
                videoSlugs={this.props.videos
                  .map((v) => ({ slug: v.slug, id: v.id }))
                  .filter((slug) => !!slug)}
                initialValues={initialValues}
                initialCategories={initialCategories}
                options={this.props.categories}
              />
            ) : null}
          </Drawer>
          {this.state.showTranslations ? (
            <VideosTranslationsModal
              visible={this.state.showTranslations}
              video={_.pick(
                videos.filter((v) => v.id === this.state.chosenVideo)[0],
                "id",
                "title",
                "subtitle"
              )}
              closeModal={() => this.setState({ showTranslations: false })}
            />
          ) : null}
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProsp = (state) => {
  return {
    videos: state.videos.videos,
    success: state.videos.success,
    vimeoVideos: state.vimeo.videos,
    categories: state.categories.categories,
    token: state.auth.token,
    galleries: state.galleries.galleries,
  };
};

export default connect(mapStateToProsp, {
  addVideo,
  updateVideo,
  getVideos,
  deleteVideo,
  getVimeoVideos,
  getCategories,
  getGalleries,
})(VideosManager);
