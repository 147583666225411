import React from "react";
import { connect } from "react-redux";
import { getConfig } from "../../../actions/appConfigActions";
import { imageServerUrl } from "../../../theme-variables";
import "./AdvertisementBlock.scss";

class AdvertisementBlock extends React.Component {
  componentDidMount = () => {
    this.props.getConfig();
  };
  render() {
    const {
      show_advertisement,
      paid_ad_url,
      free_ad_url,
      mobile_free_ad_image,
      tablet_free_ad_image,
      desktop_free_ad_image,
      mobile_paid_ad_image,
      tablet_paid_ad_image,
      desktop_paid_ad_image,
    } = this.props.config;
    const { loading, isPaid } = this.props;
    if (!loading && show_advertisement) {
      if (isPaid)
        return (
          <a href={`${paid_ad_url}`}>
            {mobile_paid_ad_image ? (
              <div
                className={`banner-block banner-block-mobile ${
                  !tablet_paid_ad_image ? "banner-block-tablet" : ""
                } ${
                  !tablet_paid_ad_image && !desktop_paid_ad_image
                    ? "banner-block-desktop"
                    : ""
                }`}
              >
                <img
                  src={`${imageServerUrl}${mobile_paid_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
            {tablet_paid_ad_image ? (
              <div
                className={`banner-block banner-block-tablet ${
                  !desktop_paid_ad_image ? "banner-block-desktop" : ""
                }`}
              >
                <img
                  src={`${imageServerUrl}${tablet_paid_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
            {desktop_paid_ad_image ? (
              <div className="banner-block banner-block-desktop">
                <img
                  src={`${imageServerUrl}${desktop_paid_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
          </a>
        );
      else
        return (
          <a href={`${free_ad_url}`}>
            {mobile_free_ad_image ? (
              <div
                className={`banner-block banner-block-mobile ${
                  !tablet_free_ad_image ? "banner-block-tablet" : ""
                } ${
                  !tablet_free_ad_image && !desktop_free_ad_image
                    ? "banner-block-desktop"
                    : ""
                }`}
              >
                <img
                  src={`${imageServerUrl}${mobile_free_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
            {tablet_free_ad_image ? (
              <div
                className={`banner-block banner-block-tablet ${
                  !desktop_free_ad_image ? "banner-block-desktop" : ""
                }`}
              >
                <img
                  src={`${imageServerUrl}${tablet_free_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
            {desktop_free_ad_image ? (
              <div className="banner-block banner-block-desktop">
                <img
                  src={`${imageServerUrl}${desktop_free_ad_image}`}
                  alt="Baner"
                />
              </div>
            ) : null}
          </a>
        );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
    loading: state.appConfig.loading,
  };
};

export default connect(mapStateToProps, { getConfig })(AdvertisementBlock);
