import React from "react";
import { Checkbox } from "antd";
import "../../managers.scss";

class CodeConfig extends React.Component {
  state = {
    code_form_in_nodes: true,
    code_form_in_dashboard: false,
  };
  toggleConfig = (e, field) => {
    let newData = {};
    newData[field] = e.target.checked ? 1 : 0;
    this.props.changeConfig(newData).then(() => {
      let newState = {};
      newState[field] = e.target.checked;
      this.setState(newState);
    });
  };
  render() {
    const { code_form_in_nodes, code_form_in_dashboard } = this.props.config;

    return (
      <>
        <label htmlFor="codeFormInNodes">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="codeFormInNodes"
            checked={code_form_in_nodes}
            type="checkbox"
            name="codeFormInNodes"
            onChange={(e) => this.toggleConfig(e, "code_form_in_nodes")}
          />
          Umożliwiaj wpisywanie kodów rabatowych na stronie wydarzenia / treści
          VOD
        </label>
        <br />
        <br />
        <label htmlFor="codeFormInDashboard">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="codeFormInDashboard"
            checked={code_form_in_dashboard}
            type="checkbox"
            name="codeFormInDashboard"
            onChange={(e) => this.toggleConfig(e, "code_form_in_dashboard")}
          />
          Umożliwiaj wpisywanie kodów rabatowych na stronie głównej VOD
        </label>
      </>
    );
  }
}

export default CodeConfig;
