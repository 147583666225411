import React from "react";
import tc from "../../../helpers/translateContent";
import { imageServerUrl } from "../../../theme-variables";

const Slide = ({ data }) => {
  return (
    <div className="slide">
      <div
        className="slide-bg"
        style={{ backgroundImage: `url(${imageServerUrl}${data.bg_image})` }}
      ></div>
      <div className="slide-content">
        <div className="slide-desc">
          <span className="slide-title">
            {tc(data.title, "title", data.translations)}
          </span>
          <div
            className="slide-text"
            dangerouslySetInnerHTML={{
              __html: tc(data.text, "text", data.translations),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Slide;
