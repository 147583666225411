import vod, { tokenConfig } from "../apis/vod";
import {
  GET_PACKETS_SUCCESS,
  GET_PACKETS_FAILURE,
  GET_PACKET_SUCCESS,
  GET_PACKET_FAILURE,
  PACKETS_LOADING,
  DELETE_PACKET_SUCCESS,
  DELETE_PACKET_FAILURE,
  ADD_PACKET_SUCCESS,
  ADD_PACKET_FAILURE,
  UPDATE_PACKET_SUCCESS,
  UPDATE_PACKET_FAILURE,
  PACKET_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getPackets =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch(setPacketsLoading());
    vod
      .get(`/api/packets`, {
        params: props,
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_PACKETS_SUCCESS,
          payload: res.data.packets,
        });
      })
      .catch((err) => {
        handleErrors(err, dispatch, GET_PACKETS_FAILURE);
      });
  };

export const getPacket = (id) => (dispatch, getState) => {
  dispatch(setPacketsLoading());
  vod
    .get(`/api/packets/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PACKET_SUCCESS,
        payload: res.data.packet,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_PACKET_FAILURE);
    });
};

export const deletePacket = (id) => (dispatch, getState) => {
  dispatch(setPacketsLoading());
  vod
    .delete(`/api/packets/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_PACKET_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_PACKET_FAILURE);
    });
};

export const addPacket = (props) => (dispatch, getState) => {
  dispatch(setPacketsLoading());

  vod
    .post(`/api/packets/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_PACKET_SUCCESS, payload: res.data.packet });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ADD_PACKET_FAILURE, PACKET_FORM_ERRORS);
    });
};

export const updatePacket = (props, id) => (dispatch, getState) => {
  dispatch(setPacketsLoading());
  vod
    .put(`/api/packets/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_PACKET_SUCCESS,
        payload: res.data.packet,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_PACKET_FAILURE, PACKET_FORM_ERRORS);
    });
};
export const setPacketsLoading = () => {
  return {
    type: PACKETS_LOADING,
  };
};
