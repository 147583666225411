import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import logo from "../../static/logo_white.svg";
import NavbarMenu from "./NavbarMenu";
import { shortVodUrl, showSearchbar } from "../../theme-variables";
import "./Navbar.scss";
class Navbar extends Component {
  state = {
    visible: false,
  };
  showDrawer = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <nav
        className={`main-navbar ${
          showSearchbar ? "searchbar-show" : ""
        }`}
      >
        <div className="main-navbar-content">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo Teapp" />
              <span>{shortVodUrl || "VOD"}</span>
            </Link>
          </div>
          <div className="right-col">
            <NavbarMenu onClose={this.onClose} />
            <div className="barsMenu" onClick={this.showDrawer}>
              {this.state.visible ? (
                <CloseOutlined />
              ) : (
                <MenuOutlined />
              )}
            </div>
            <Drawer
              placement="top"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
              className="navbar-drawer"
            >
              <NavbarMenu onClose={this.onClose} drawer={true} />
            </Drawer>
          </div>
        </div>
      </nav>
    );
  }
}
export default Navbar;
