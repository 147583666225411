import React from "react";
import { Table, Popconfirm, Tag, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  TranslationOutlined,
  UpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./SlideList.scss";
import { imageServerUrl } from "../../../../../theme-variables";

class SlideList extends React.Component {
  state = { dataSource: this.props.slides };

  render() {
    const columns = [
      {
        title: "Tytuł",
        dataIndex: "title",
        render: (v, r) => {
          return (
            <>
              {v}
              <br />
              <br />
              {r.published ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  Opublikowany
                </Tag>
              ) : (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  Nieopublikowany
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "Tekst",
        dataIndex: "text",
        render: (v) => {
          return <div dangerouslySetInnerHTML={{ __html: v }} />;
        },
      },
      {
        title: "Obraz",
        dataIndex: "bg_image",
        render: (v, r) => {
          return (
            <img
              src={imageServerUrl + v}
              alt={`Zdjęcie ${r.title}`}
              style={{ maxHeight: 150 }}
            />
          );
        },
      },
      {
        title: "Akcje",
        key: "action",
        width: "20%",
        render: (text, record) => (
          <>
            <Tooltip title="Edytuj slajd">
              <EditOutlined
                key="edit"
                onClick={() => this.props.editSlideClick(record.id)}
                style={{ fontSize: "20px", margin: "5px 10px" }}
              />
            </Tooltip>

            <Tooltip title="Usuń slajd">
              <Popconfirm
                title={`Czy na pewno chcesz usunąć slajd: ${record.title}?`}
                onConfirm={() => this.props.deleteSlideClick(record.id)}
                okText="Tak"
                cancelText="Nie"
              >
                <DeleteOutlined
                  key="delete"
                  style={{ fontSize: "20px", margin: "5px 10px" }}
                />
              </Popconfirm>
            </Tooltip>

            <Tooltip title="Zarządzaj tłumaczeniami">
              <TranslationOutlined
                key="translations"
                onClick={() => this.props.manageTranslationsClick(record.id)}
                style={{ fontSize: "20px", margin: "5px 10px" }}
              />
            </Tooltip>

            <Tooltip title="Przesuń do góry">
              <UpOutlined
                key="up"
                onClick={() => this.props.updateSlide({}, record.id)}
                style={{ fontSize: "20px", margin: "5px 10px" }}
              />
            </Tooltip>
          </>
        ),
      },
    ];
    const { dataSource } = this.state;
    return (
      <>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
        />
      </>
    );
  }
}

export default SlideList;
