import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import vod from "../../../../apis/vod";
import { Card, Button, message, Popconfirm, Tag } from "antd";
import {
  updateUser,
  deleteUser,
  clearUpdateUserState,
} from "../../../../actions/authActions";
import { deleteBan, getBans, banUser } from "../../../../actions/bansActions";
import {
  getUserCodes,
  activateCode,
  deleteUserCode,
} from "../../../../actions/codesActions";
import Wrapper from "../../../wrappers/Wrapper";
import EditUserForm from "../../../pages/userPage/EditUserForm";
import UserMailingSettings from "../../../pages/userPage/UserMailingSettings";
import UserCodes from "../../../pages/userPage/UserCodes";
import UsersBansList from "./lists/UsersBansList";
import BanForm from "./forms/BanForm";
import { getDataSourceForUserPaymentList } from "../../../../helpers/productUtilities";
import UserPaymentList from "../../../pages/userPage/UserPaymentList";
import { withTranslation } from "react-i18next";
import { fetchAddresses } from "../../../../theme-variables";
import UserGiftList from "../../../pages/userPage/UserGiftList";
import UserGroupTicketList from "../../../pages/userPage/UserGroupTicketList";

class UserPageAdmin extends React.Component {
  state = { orders: [], edit: false };
  onClick = () => {
    this.setState({ edit: !this.state.edit });
  };
  onSubmit = (formValues) => {
    this.props.updateUser(formValues, this.props.match.params.id);
  };
  fetchUser = () => {
    vod
      .get(`/api/users/${this.props.match.params.id}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          orders: res.data.orders,
          videoOrders: res.data.videoOrders,
          giftOrders: res.data.giftOrders,
          groupTicketOrders: res.data.groupTicketOrders,
          packetOrders: res.data.packetOrders,
          user: res.data.user,
          bans: res.data.bans,
        });
      })
      .catch((err) => {
        message.error("Wystąpił błąd: " + err);
      });
  };
  deleteCode = (codeId) => {
    this.props.deleteUserCode(this.props.match.params.id, codeId);
  };
  activateCode = (codeId) => {
    this.props.activateCode(this.props.match.params.id, codeId);
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userCodes !== this.props.userCodes &&
      this.props.userCodes.length > 0
    ) {
      if (this.props.activateSuccess !== null && this.props.activateSuccess)
        message.success("Kod został aktywowany");
      else if (this.props.activateSuccess !== null && this.props.codeError)
        message.error(this.props.codeError);
    }
    if (
      prevProps.updateUserState.success !==
        this.props.updateUserState.success &&
      this.props.updateUserState.success &&
      prevState.edit
    ) {
      this.fetchUser();
      this.props.clearUpdateUserState();
      this.setState({ edit: false });
    }
  }
  componentDidMount() {
    this.fetchUser();
    this.props.getBans();
    this.props.getUserCodes(this.props.match.params.id);
  }
  renderRoleName(roleId, getSlug = false) {
    switch (roleId) {
      case 2:
        return !getSlug ? "standardowy" : "standard";
      case 3:
        return !getSlug ? "użytkownik premium" : "premium";
      case 4:
        return !getSlug ? "niezweryfikowany" : "unverified";
      case 5:
        return !getSlug ? "usunięty" : "deleted";
      default:
        return !getSlug ? "administrator" : "admin";
    }
  }
  deleteAccount = () => {
    this.props.deleteUser(this.props.match.params.id);
  };
  banUser = (formValues) => {
    this.props.banUser({
      expiration_date: formValues.expiration_date
        ? formValues.expiration_date.format("YYYY-MM-DD HH:mm")
        : null,
      reason: formValues.reason || "nie określono",
      send_email: formValues.send_email ? true : false,
      forever: formValues.forever ? true : false,
      user_id: this.props.match.params.id,
    });
  };
  render() {
    const { t } = this.props;
    if (this.state.user) {
      const {
        firstname,
        lastname,
        email,
        created_at,
        birthday,
        role_id,
        street,
        postal_code,
        country_code,
        city,
        state,
        last_login,
        last_ip,
        country,
        provider,
        mfa,
      } = this.state.user;

      const dataSource = getDataSourceForUserPaymentList(
        this.state.orders,
        this.state.videoOrders,
        this.state.giftOrders,
        this.state.groupTicketOrders,
        this.state.packetOrders,
        t
      );
      return (
        <Wrapper>
          <h1>
            {firstname} {lastname}
          </h1>
          <Card
            key="user-summary"
            title={`Dane użytkownika - ${email}`}
            style={{ margin: "20px 0" }}
            extra={
              <Button
                type={this.state.edit ? "default" : "primary"}
                onClick={this.onClick}
              >
                {this.state.edit ? "Cofnij" : "Edytuj"}
              </Button>
            }
          >
            {this.state.edit ? (
              <EditUserForm
                onSubmit={this.onSubmit}
                updateUserState={this.props.updateUserState}
                role={this.props.role}
                initialValues={{
                  firstname,
                  lastname,
                  email,
                  birthday: moment(birthday),
                  role: this.renderRoleName(role_id, true),
                  street,
                  postal_code,
                  country_code: country_code || "PL",
                  city,
                  state,
                  mfa,
                }}
              />
            ) : (
              <>
                {provider ? (
                  <>
                    <p style={{ maxWidth: 500 }}>
                      Dane osobowe tego użytkownika zarządzane są przez
                      platformę teatr.<u>link</u>. Jeżeli zmodyfikujesz te dane
                      to zostaną one nadpisane przy jego kolejnym zalogowaniu.
                    </p>
                    <Tag color="#1010f3" style={{ marginBottom: 10 }}>
                      Konto teatr.<u>link</u>
                    </Tag>
                  </>
                ) : null}
                <p key="firstname">
                  <b>Imię: </b>
                  {firstname}
                </p>
                <p key="lastname">
                  <b>Nazwisko: </b>
                  {lastname}
                </p>
                <p key="email">
                  <b>Email: </b>
                  {email}
                </p>
                <p key="login">
                  <b>
                    Ostatnie logowanie:
                    <br />
                  </b>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {last_login
                      ? moment(last_login).format("H:mm, DD.MM.YYYY")
                      : "Brak logowania"}
                    {last_login && country ? (
                      <img
                        alt={`Flaga ${country}`}
                        height="22"
                        style={{ margin: "0 10px" }}
                        src={`https://cdn.ipregistry.co/flags/twemoji/${country.toLowerCase()}.svg`}
                      />
                    ) : null}
                    {last_login && last_ip ? ` (${last_ip})` : ""}
                  </span>
                </p>
                {fetchAddresses ? (
                  street ? (
                    <p key="address">
                      <b>{t("userpage.address")}: </b>
                      <br />
                      {street}
                      <br />
                      {postal_code} {city}
                      <br />
                      {state}, {country_code}
                    </p>
                  ) : (
                    <p key="address">
                      <b>{t("userpage.address")}: </b>
                      <br />
                      {t("userpage.no_address")}
                    </p>
                  )
                ) : (
                  <p key="address">
                    <b>{t("form.placeholder.country_code")}: </b>
                    {country_code || "PL"}
                  </p>
                )}
                <p key="registration_date">
                  <b>Data rejestracji: </b>
                  {moment(created_at).format("H:mm, DD.MM.YYYY")}
                </p>
                <p key="birthday">
                  <b>Data urodzin: </b>
                  {moment(birthday).format("DD.MM.YYYY")}
                </p>
                <p key="role">
                  <b>Rola użytkownika: </b>
                  {this.renderRoleName(role_id)}
                </p>
                <p key="mfa">
                  <b>{t("form.placeholder.mfa")}: </b>
                  {mfa ? t("const.on") : t("const.off")}
                </p>
              </>
            )}
          </Card>
          <UserCodes
            user={this.props.user}
            activateCode={this.activateCode}
            userCodes={this.props.userCodes}
            deleteUserCode={this.deleteCode}
            codeError={this.props.codeError}
          />
          <UserMailingSettings
            user={this.state.user}
            token={this.props.token}
            admin={true}
          />
          {this.state.giftOrders.length ? (
            <Card
              title={t("userpage.your_gifts")}
              style={{ marginBottom: "30px" }}
            >
              <UserGiftList orders={this.state.giftOrders} admin={true} />
            </Card>
          ) : null}
          {this.state.groupTicketOrders.length ? (
            <Card
              title={t("userpage.your_group_tickets")}
              style={{ marginBottom: "30px" }}
            >
              <UserGroupTicketList orders={this.state.groupTicketOrders} />
            </Card>
          ) : null}
          <Card
            key="user-orders"
            title="Zamówienia użytkownika"
            style={{ marginBottom: "30px" }}
          >
            <UserPaymentList dataSource={dataSource} />
          </Card>

          {this.props.bans.length > 0 ? (
            <Card
              key="user-bans"
              title="Blokady użytkownika"
              style={{ marginBottom: "30px" }}
            >
              <UsersBansList
                bans={this.props.bans}
                deleteBan={this.props.deleteBan}
              />
            </Card>
          ) : null}

          <Card
            key="user-ban"
            title="Blokowanie użytkownika"
            style={{ marginBottom: "30px" }}
          >
            <BanForm onSubmit={this.banUser} />
          </Card>

          <Card
            key="account-deleting"
            title="Usuwanie konta"
            style={{ marginBottom: "30px" }}
          >
            <p>
              UWAGA! Usunięcie konta spowoduje utratę wszystkich dostępów do
              wydarzeń oraz filmów.
            </p>
            <Popconfirm
              title="Czy na pewno chcesz usunąć konto?"
              onConfirm={() => this.deleteAccount()}
              okText="Tak"
              cancelText="Nie"
            >
              <Button className="red">Usuń konto</Button>
            </Popconfirm>
          </Card>
        </Wrapper>
      );
    } else {
      return <Wrapper>Wczytywanie ...</Wrapper>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    updateUserState: state.auth.updateUser,
    bans: state.bans.bans.filter((ban) => {
      return ban.user_id.toString() === ownProps.match.params.id;
    }),
    token: state.auth.token,
    userCodes: state.codes.userCodes,
    activateSuccess: state.codes.activateSuccess,
    codeError: state.codes.error,
    role: state.auth.user.role,
  };
};

export default connect(mapStateToProps, {
  updateUser,
  deleteBan,
  banUser,
  getBans,
  deleteUser,
  getUserCodes,
  activateCode,
  deleteUserCode,
  clearUpdateUserState,
})(withTranslation()(UserPageAdmin));
