import React from "react";
import { connect } from "react-redux";
import qs from "query-string";
import { Link } from "react-router-dom";
import { Col, Row, message, notification } from "antd";
import {
  signInUser,
  clearSignIn,
  signInAdmin,
} from "../../../actions/authActions";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import SingleButton from "../../form-components/SingleButton";
import SignInForm from "./SignInForm";
import { withTranslation } from "react-i18next";
import TlImg from "../../../static/tl.png";
import PauseImg from "../../../static/pause.png";
import { linkIntegration } from "../../../theme-variables";
import vod from "../../../apis/vod";

class SignIn extends React.Component {
  getRedirectionPage = () => {
    let from = null;
    if (
      qs.parse(this.props.location.search).redirect &&
      qs.parse(this.props.location.search).redirect !== "/strona-glowna"
    )
      from = qs.parse(this.props.location.search).redirect;
    else if (
      this.props.location.state &&
      this.props.location.state.from.pathname !== "/konto"
    )
      from = this.props.location.state.from.pathname;

    if (from) from = from.replace("/publiczny", "");
    return from;
  };
  redirectToTl = () => {
    vod
      .get("/api/auth/url", { withCredentials: true })
      .then(({ data: { url } }) => {
        if (url) window.location.href = url;
      })
      .catch((err) => {
        notification.error({
          message: this.props.t("const.error_occured"),
        });
      });
  };
  onSubmit = (formValues) => {
    const from = this.getRedirectionPage();
    this.props.signInUser(formValues, from);
  };
  componentWillUnmount() {
    this.props.clearSignIn();
  }
  componentDidMount() {
    this.props.getConfig(false);
    const { t } = this.props;
    if (qs.parse(this.props.location.search).anotherUser)
      message.error(
        t(
          "signin.another_user",
          "Ktoś zalogował się na Twoje konto z innego urządzenia. Jeżeli to nie Ty zalogowałeś/aś się na konto, prosimy o zresetowanie hasła."
        )
      );

    if (qs.parse(this.props.location.search).wrongToken)
      message.error(
        t(
          "signin.wrong_token",
          "Niepoprawny token logowania - link wygasł lub jest nieprawidłowy."
        )
      );

    if (qs.parse(this.props.location.search).sessionEnd)
      message.error(
        t(
          "signin.session_timeout",
          "Twoja sesja wygasła. Zaloguj się ponownie."
        )
      );

    if (qs.parse(this.props.location.search).userBlocked)
      message.error(
        t(
          "signin.account_blocked",
          "Twoje konto zostało zablokowane. Prosimy o kontakt z administratorem."
        )
      );

    if (qs.parse(this.props.location.search).tlError)
      message.error(
        t(
          "auth.error",
          "Wystąpił błąd podczas logowania. Prosimy spróbować ponownie."
        )
      );

    if (qs.parse(this.props.location.search).tlAlreadyRegistered)
      message.error(
        t(
          "auth.error_already_registered",
          "Posiadasz już standardowe konto w tym serwisie. Prosimy zalogować się za pomocą formularza poniżej."
        )
      );

    const { login_token, login_email } = qs.parse(this.props.location.search);
    if (!!login_token && !!login_email) {
      const from = this.getRedirectionPage();
      this.props.signInAdmin({ login_token, login_email }, from);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Wrapper
        title={t("signin.title")}
        bg={this.props.appConfig.noauth_bg_image}
        authLayout={true}
      >
        <Row gutter={[10, 40]} className="loginButtons">
          <Col span={12}>
            <SingleButton block disabled className="filled">
              {t("signin.have_account")}
            </SingleButton>
          </Col>
          <Col span={12}>
            <Link to={`/rejestracja`} className="ui button primary">
              <SingleButton style={{ marginBottom: 20 }} block>
                {t("signin.create_account")}
              </SingleButton>
            </Link>
          </Col>
          {linkIntegration ? (
            <Col span={24}>
              <SingleButton
                block
                onClick={this.redirectToTl}
                className="link-button"
              >
                {t("signin.login_with_tl")} <img src={PauseImg} />{" "}
                <img src={TlImg} />
              </SingleButton>
            </Col>
          ) : null}
        </Row>
        <SignInForm
          signInState={this.props.signInState}
          onSubmit={this.onSubmit}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signInState: state.auth.signIn,
    appConfig: state.appConfig.config,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    clearSignIn,
    signInUser,
    signInAdmin,
    getConfig,
  })(SignIn)
);
