import React from "react";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { Alert, Button, DatePicker, TimePicker, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { makeField } from "../../../../form-components/makeComponent";
import MultipleSelect from "../../../../form-components/MultipleSelect";

class PacketForm extends React.Component {
  onSubmit = (formValues) => {
    formValues.events = JSON.stringify(this.getSelectedEvents());
    formValues.videos = JSON.stringify(this.getSelectedVideos());
    this.props.onSubmit(formValues);
  };
  getSelectedEvents = () => {
    const selectedEvents = document.querySelectorAll(
      ".eventSelect .ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content"
    );
    let eventsArray = [];
    for (let i = 0; i < selectedEvents.length; i++) {
      const name = selectedEvents[i].innerHTML;
      const eventId = name.substr(
        name.lastIndexOf("(") + 1,
        name.lastIndexOf(")") - 1
      );
      if (eventId !== null) eventsArray.push(parseInt(eventId));
    }
    return eventsArray;
  };
  getSelectedVideos = () => {
    const selectedVideos = document.querySelectorAll(
      ".videoSelect .ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content"
    );
    let videosArray = [];
    for (let i = 0; i < selectedVideos.length; i++) {
      const name = selectedVideos[i].innerHTML;
      const videoId = name.substr(
        name.lastIndexOf("(") + 1,
        name.lastIndexOf(")") - 1
      );
      if (videoId !== null) videosArray.push(parseInt(videoId));
    }
    return videosArray;
  };
  render() {
    const error = this.props.error;
    let defaultEventsValue = this.props.initialEvents
      ? this.props.initialEvents.map((eventId) => {
          const eventObj = this.props.events.find((o) => o.id === eventId);
          if (eventObj) return `${eventObj.name} (${eventObj.id})`;
          return undefined;
        })
      : [];
    let defaultVideosValue = this.props.initialVideos
      ? this.props.initialVideos.map((videoId) => {
          const videoObj = this.props.videos.find((o) => o.id === videoId);
          if (videoObj) return `${videoObj.name} (${videoObj.id})`;
          return undefined;
        })
      : [];
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł pakietu"
          type="text"
        />
        <Field
          name="description"
          placeholder="Opis pakietu (kod html)"
          component="textarea"
        />
        <Field
          name="price"
          component={Input}
          placeholder="Cena"
          suffix={" zł"}
          type="number"
          step="0.01"
          style={{ width: "150px" }}
        />
        <Field
          className="datePicker"
          showTime
          name="exp_date"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Dostępny do:"}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <Field
          name="videos"
          component={MultipleSelect}
          options={this.props.videos.map((v) => {
            v.name = v.title;
            return v;
          })}
          className="videoSelect"
          label="Treści VOD w pakiecie"
          placeholder="Wybierz treści VOD"
          defaultValue={defaultVideosValue}
        />
        <Field
          name="events"
          component={MultipleSelect}
          options={this.props.events.map((v) => {
            v.name = v.title;
            return v;
          })}
          className="eventSelect"
          label="Wydarzenia w pakiecie"
          placeholder="Wybierz wydarzenia"
          defaultValue={defaultEventsValue}
        />
        <Field
          name="force_visibility"
          component={Checkbox}
          type="checkbox"
          label="Wymuś wyświetlenie pakietu"
          suffix={
            <Tooltip
              style={{ margin: "0 8px", color: "blue" }}
              title="Zaznacz jeżeli pakiet ma być widoczny mimo, że część treści jest niedostępna (np. zakończone wydarzenie lub nieopublikowana treść VOD)"
            >
              <InfoCircleOutlined
                className="site-form-item-icon"
                style={{ color: "rgba(0,0,0,.45)" }}
              />
            </Tooltip>
          }
          size="big"
        />
        <Field
          name="published"
          component={Checkbox}
          type="checkbox"
          label="Opublikowany"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli pakiet ma być dostępne dla użytkowników">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {error ? <Alert message={error} type="error" showIcon /> : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.title) errors.title = "Proszę podać tytuł.";
  if (!formValues.description) errors.description = "Proszę podać odnośnik.";
  if (!formValues.price || parseFloat(formValues.price) == 0)
    errors.price = "Proszę podać cenę.";

  if (formValues.title && formValues.title.length > 100)
    errors.title = "Maksymalnie 100 znaków.";

  if (
    formValues.price !== undefined &&
    !/^\d+(.\d{1,2})?$/.test(formValues.price)
  )
    errors.price = "Niepoprawna wartość";

  if (
    formValues.age_restriction !== undefined &&
    formValues.age_restriction !== null &&
    formValues.age_restriction !== "" &&
    isNaN(parseInt(formValues.age_restriction))
  )
    errors.age_restriction = "Niepoprawna wartość";

  if (!formValues.exp_date || !moment(formValues.exp_date).isValid())
    errors.exp_date = "Nieprawidłowa data.";
  return errors;
};

export default reduxForm({ form: "packetForm", validate })(PacketForm);
