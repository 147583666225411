import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  List,
  Card,
  Table,
  DatePicker,
  Button,
  Select,
  Space,
  Input,
  Skeleton,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { makeField } from "../../../../form-components/makeComponent";
import {
  renderTagByPaymentStatus,
  getDataSourceForPacketPaymentList,
} from "../../../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

class PacketPaymentList extends React.Component {
  state = {
    packetSelect: null,
    dateFrom: null,
    dateTo: null,
    searchText: "",
    searchedColumn: "",
    paymentCsvData: [],
    dataSource: [],
    loadingStatistics: false,
    status: undefined,
  };
  onSubmit = (formValues) => {
    this.setState({
      dateFrom: formValues.date_from,
      dateTo: formValues.date_to,
      status: formValues.status,
      loadingStatistics: true,
    });
    this.props.onSubmit(formValues);
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Szukaj...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Szukaj
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 110 }}
          >
            Wyczyść
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (v, r) => {
      if (dataIndex === "user") {
        if (
          r.firstname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.lastname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.email.toString().toLowerCase().includes(v.toLowerCase())
        )
          return true;
        else return "";
      }
      if (dataIndex === "order") {
        if (
          r.extOrderId.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.orderId.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.created_at.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.amount.toString().toLowerCase().includes(v.toLowerCase())
        )
          return true;
        else return "";
      }
      return r[dataIndex]
        ? r[dataIndex].toString().toLowerCase().includes(v.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  onDateRangeChange = (value, dateString) => {
    if (value !== null) {
      this.props.change("date_from", value[0].format("YYYY-MM-DD"));
      this.props.change("date_to", value[1].format("YYYY-MM-DD"));
    } else {
      this.props.change("date_from", null);
      this.props.change("date_to", null);
    }
  };
  countStatistics = (orders) => {
    const incomeWithRefunds =
      orders
        .map((o) => {
          if (
            o.status === "COMPLETED" ||
            (o.status === "REFUNDED" &&
              moment(o.updated_at).isAfter(this.state.dateTo))
          )
            return Number.parseFloat(o.amount);
          return 0;
        })
        .reduce((a, b) => a + b, 0) -
      (Number.parseFloat(this.props.refunds) || 0);
    let statistics = [
      {
        key: "Liczba złożonych zamówień",
        value: orders.length,
      },
      {
        key: "Liczba zrealizowanych, płatnych zamówień",
        value: orders.filter((o) => o.status === "COMPLETED" && o.amount > 0)
          .length,
      },
    ];
    if (this.state.status === "COMPLETED" || this.state.status === undefined) {
      statistics = [
        ...statistics,
        {
          key: "Wartość sprzedanych biletów (z uwzględnieniem zwrotów)",
          value:
            orders
              .map((o) => {
                if (o.status === "COMPLETED")
                  return Number.parseFloat(o.amount);
                return 0;
              })
              .reduce((a, b) => a + b, 0) + " zł",
        },
        {
          key: "Dochód",
          value: incomeWithRefunds + " zł",
        },
        {
          key: "Suma zwrotów w danym zakresie",
          value: (Number.parseFloat(this.props.refunds) || 0) + " zł",
        },
      ];
    }
    return statistics;
  };
  onPacketChange = (v) => {
    this.setState({
      packetSelect: v,
    });
  };
  generateCsvData = (dataSource) => {
    const { dateFrom, dateTo } = this.state;
    const dateRange =
      dateFrom && dateTo
        ? dateFrom !== dateTo
          ? `${moment(dateFrom).format("DD.MM.YYYY")} - ${moment(dateTo).format(
              "DD.MM.YYYY"
            )}`
          : moment(dateFrom).format("DD.MM.YYYY")
        : moment().format("DD.MM.YYYY");
    let csvData = [
      ["Zakres dat:", dateRange],
      [
        "Liczba zrealizowanych, płatnych zamówień",
        "Wartość sprzedanych biletów (z uwzględnieniem zwrotów) [zł]",
        "Dochód",
        "Suma zwrotów w danym zakresie",
      ],
      [
        dataSource.filter((o) => o.status === "COMPLETED" && o.amount > 0)
          .length,
        dataSource
          .map((o) => {
            if (o.status === "COMPLETED") return Number.parseFloat(o.amount);
            return 0;
          })
          .reduce((a, b) => a + b, 0),
        dataSource
          .map((o) => {
            if (
              o.status === "COMPLETED" ||
              (o.status === "REFUNDED" &&
                moment(o.updated_at).isAfter(this.state.dateTo))
            )
              return Number.parseFloat(o.amount);
            return 0;
          })
          .reduce((a, b) => a + b, 0) -
          (Number.parseFloat(this.props.refunds) || 0),
        Number.parseFloat(this.props.refunds) || 0,
      ],
      [],
      [],
      ["Lista płatności"],
      [
        "Imię i nazwisko",
        "Email",
        "Numer zamówienia w systemie VOD",
        "Numer zamówienia w systemie płatności",
        "Cena",
        "Data utworzenia",
        "Kraj",
        "Adres IP",
        "Status płatności",
      ],
    ];
    dataSource.forEach((r) => {
      csvData.push([
        r.firstname + " " + r.lastname,
        r.email,
        r.orderId,
        r.extOrderId,
        parseFloat(r.amount).toFixed(2).toString().replace(".", ","),
        moment(r.created_at).format("H:mm, DD.MM.YYYY"),
        r.country,
        r.customer_ip,
        r.translatedStatus,
      ]);
    });
    return csvData;
  };
  handlePaymentsTableChange = (pagination, filters, sorter, extra) => {
    this.setState({
      paymentCsvData: this.generateCsvData(extra.currentDataSource),
    });
  };
  componentDidMount = () => {
    const dataSource = getDataSourceForPacketPaymentList(
      this.props.orders,
      this.props.t
    );
    this.setState({
      dataSource,
      paymentCsvData: this.generateCsvData(dataSource),
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orders !== this.props.orders) {
      const dataSource = getDataSourceForPacketPaymentList(
        this.props.orders,
        this.props.t
      );
      this.setState({
        dataSource,
        paymentCsvData: this.generateCsvData(dataSource),
        loadingStatistics: false,
      });
    }
  }
  render() {
    const { t } = this.props;
    const { dateFrom, dateTo, packetSelect } = this.state;
    let dataSource = this.state.dataSource;

    const dateRange =
      dateFrom && dateTo
        ? dateFrom !== dateTo
          ? `${moment(dateFrom).format("DD.MM.YYYY")} - ${moment(dateTo).format(
              "DD.MM.YYYY"
            )}`
          : moment(dateFrom).format("DD.MM.YYYY")
        : `${moment().startOf("month").format("DD.MM.YYYY")} - ${moment()
            .endOf("month")
            .format("DD.MM.YYYY")}`;

    if (packetSelect)
      dataSource = dataSource.filter((o) => o.packet_id === packetSelect);
    return (
      <Card title="Lista płatności" className="payment-list">
        <p>
          Domyślnie wyświetlają się płatności z danego miesiąca. Aby wyświetlić
          starsze płatności, należy podać zakres dat.
        </p>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="payment-list-form"
        >
          <Field
            label="Zakres dat"
            name="rangepicker"
            component={makeField(DatePicker.RangePicker, "DD.MM.YYYY")}
            placeholder={["Od", "Do"]}
            hasFeedback
            onChange={this.onDateRangeChange}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          />
          <div>
            <label style={{ display: "inline-block" }}>
              Status zamówienia:
            </label>
            <Field name="status" component="select">
              <option value="">Wszystkie statusy</option>
              <option value="COMPLETED">Zakończone</option>
              <option value="CANCELED">Anulowane</option>
              <option value="REFUNDED">Zwrócone</option>
              <option value="PENDING">Przetwarzane</option>
            </Field>
          </div>
          <br />
          <Button type="primary" htmlType="submit">
            Szukaj
          </Button>
        </form>
        <br />
        <hr />
        <br />
        <h2>Podsumowanie dla: {dateRange}</h2>
        <h3>Dodatkowe filtry</h3>
        <label>
          <span style={{ minWidth: 150, display: "inline-block" }}>
            Wybierz pakiet:
          </span>
          <Select
            id="packetSelect"
            defaultValue=""
            allowClear
            style={{ width: "100%", maxWidth: 400 }}
            showSearch
            dropdownMatchSelectWidth={false}
            value={this.state.packetSelect}
            onChange={this.onPacketChange}
            optionFilterProp={"children"}
          >
            {this.props.packets.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.title}{" "}
                <Link target="_blank" to={`/platnosc/pakiet/${p.id}`}>
                  link
                </Link>
              </Select.Option>
            ))}
          </Select>
        </label>
        <br />
        <br />
        {!this.state.loadingStatistics ? (
          <List
            header="Podsumowanie z uwzględnieniem powyższych filtrów (domyślnie: aktualny miesiąc)"
            itemLayout="horizontal"
            size="small"
            dataSource={this.countStatistics(dataSource)}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <p style={{ marginBottom: 0 }}>
                      <strong>{item.key}: </strong>
                      {item.value}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Skeleton />
        )}
        <Table
          dataSource={dataSource}
          pagination={{ defaultPageSize: 20 }}
          onChange={this.handlePaymentsTableChange}
        >
          <Column
            title="Użytkownik"
            dataIndex="user"
            key="user"
            width="250px"
            sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
            {...this.getColumnSearchProps("user")}
            render={(v, r) => {
              return this.state.searchedColumn === "user" ? (
                <Link to={`/konto/${r.userId}`} style={{ color: "black" }}>
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: "#ffc069",
                      padding: 0,
                    }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={`${r.firstname} ${r.lastname} ${r.email}`}
                  />
                </Link>
              ) : (
                <Link to={`/konto/${r.userId}`} style={{ color: "black" }}>
                  {r.firstname} {r.lastname}
                  <br />
                  {r.email}
                </Link>
              );
            }}
          />
          <Column
            title="Kraj i adres IP"
            dataIndex="country"
            key="country"
            sorter={(a, b) => a.country.localeCompare(b.country)}
            {...this.getColumnSearchProps("country")}
            render={(v, r) => {
              return this.state.searchedColumn === "country" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {r.country ? (
                    <>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: "#ffc069",
                          padding: 0,
                        }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={r.country}
                      />
                      <img
                        alt={`Flaga ${r.country}`}
                        height="17"
                        style={{ margin: "0 5px" }}
                        src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                      />
                      ({r.customer_ip})
                    </>
                  ) : (
                    r.customer_ip
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {r.country ? (
                    <>
                      {r.country}{" "}
                      <img
                        alt={`Flaga ${r.country}`}
                        height="22"
                        style={{ margin: "0 10px" }}
                        src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                      />
                      ({r.customer_ip})
                    </>
                  ) : (
                    r.customer_ip
                  )}
                </div>
              );
            }}
          />
          <Column
            title="Dane zamówienia"
            dataIndex="order"
            key="order"
            width="250px"
            sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
            {...this.getColumnSearchProps("order")}
            render={(v, r) => {
              return this.state.searchedColumn === "order" ? (
                <Highlighter
                  highlightStyle={{
                    backgroundColor: "#ffc069",
                    padding: 0,
                  }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={`Nr: ${r.extOrderId}\nNr w Payu: ${r.orderId}\nUtworzono: ${r.updatedDate}\nKoszt: ${r.amount}`}
                />
              ) : (
                `Nr: ${r.extOrderId}\nNr w Payu: ${r.orderId}\nUtworzono: ${r.updatedDate}\nKoszt: ${r.amount}`
              );
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            {...this.getColumnSearchProps("status")}
            render={(v) => {
              return renderTagByPaymentStatus(v, t);
            }}
            sorter={(a, b) => a.status - b.status}
          />
        </Table>
        <br />
        <Button type="primary">
          <CSVLink
            data={this.state.paymentCsvData}
            separator={";"}
            filename={`${dateRange} - podsumowanie zamówien pakietów.csv`}
          >
            Eksportuj tabelę do CSV
          </CSVLink>
        </Button>
      </Card>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (moment(formValues.date_from).isAfter(moment(formValues.date_to)))
    errors.rangepicker = "Niepoprawna data";

  return errors;
};

export default reduxForm({ form: "packetPaymentListForm", validate })(
  withTranslation()(PacketPaymentList)
);
