import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
  Card,
  Button,
  Table,
  Tag,
  Popconfirm,
  DatePicker,
  Tooltip,
  Checkbox,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  TranslationOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { makeField } from "../../../../form-components/makeComponent";
import CustomSelect from "../../../../form-components/CustomSelect";
import {
  checkEventStatusByDate,
  getDatetime,
  getDatetimeRange,
} from "../../../../../helpers/productUtilities";

class PacketList extends React.Component {
  state = { only_festival: false };
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  getDataSource() {
    let dataSource = this.props.packets;
    return dataSource;
  }
  render() {
    const dataSource = this.getDataSource();
    return (
      <Card
        style={{ marginBottom: "30px" }}
        title="Lista pakietów"
        extra={
          <Button type="primary" onClick={this.props.addPacketClick}>
            Dodaj pakiet
          </Button>
        }
        className="packet-list"
      >
        <Table
          dataSource={dataSource}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
        >
          <Column
            title="Tytuł"
            dataIndex="title"
            key="title"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            render={(v, r) => {
              return (
                <>
                  {v}
                  <br />
                  {r.published && moment().isBefore(moment(r.exp_date)) ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Opublikowany
                    </Tag>
                  ) : (
                    <Tag icon={<CloseCircleOutlined />} color="error">
                      Nieopublikowany
                    </Tag>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Dostępny do"
            dataIndex="range"
            key="range"
            sorter={(a, b) => {
              return moment(a.exp_date).isBefore(moment(b.exp_date));
            }}
            render={(v, r) => {
              return <div>{moment(r.exp_date).format("H:mm, DD.MM.YYYY")}</div>;
            }}
          />
          <Column
            title="Treści w pakiecie (przekreślone - niedostępne)"
            dataIndex="product_list"
            key="product_list"
            render={(v, r) => {
              return (
                <ul>
                  {r.events.map((e) => (
                    <li key={`list-e-${e.id}`}>
                      {e.included ? (
                        <>
                          {e.title} ({e.id})
                        </>
                      ) : (
                        <del>
                          {e.title} ({e.id})
                        </del>
                      )}
                    </li>
                  ))}
                  {r.videos.map((v) => (
                    <li key={`list-v-${v.id}`}>
                      {v.included ? (
                        <>
                          {v.title} ({v.id})
                        </>
                      ) : (
                        <del>
                          {v.title} ({v.id})
                        </del>
                      )}
                    </li>
                  ))}
                </ul>
              );
            }}
          />
          <Column
            title="Cena"
            dataIndex="price"
            key="price"
            sorter={(a, b) => a.price - b.price}
            render={(val) => val + " zł"}
          />
          <Column
            title="Akcje"
            key="action"
            render={(text, record) => (
              <div style={{ fontSize: 25 }}>
                <Tooltip title="Strona zakupu pakietu">
                  <Link
                    to={`/platnosc/pakiet/${record.id}`}
                    style={{ color: "black" }}
                  >
                    <EyeOutlined key="edit" />
                  </Link>
                </Tooltip>

                <Tooltip title="Edytuj pakiet">
                  <EditOutlined
                    key="edit"
                    onClick={() => this.props.editPacketClick(record.id)}
                  />
                </Tooltip>

                <Tooltip title="Zarządzaj tłumaczeniami">
                  <TranslationOutlined
                    key="translations"
                    onClick={() =>
                      this.props.manageTranslationsClick(record.id)
                    }
                  />
                </Tooltip>

                <Tooltip title="Usuń pakiet">
                  <Popconfirm
                    title={`Czy na pewno chcesz usunąć pakiet: ${record.title}?`}
                    onConfirm={() => this.props.deletePacketClick(record.id)}
                    okText="Tak"
                    cancelText="Nie"
                  >
                    <DeleteOutlined key="delete" />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          />
        </Table>
      </Card>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
};

export default reduxForm({ form: "packetListForm", validate })(PacketList);
