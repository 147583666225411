import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signOutUser } from "../../actions/authActions";
import userIcon from "../../static/user.svg";
import logoutIcon from "../../static/logout.svg";
import loginIcon from "../../static/login.svg";
import settingsIcon from "../../static/settings.svg";
import searchIcon from "../../static/search.svg";
import adminMenuItems from "../admin/menu/adminMenuItems";
import { showSearchbar } from "../../theme-variables";
import { withTranslation } from "react-i18next";

class NavbarMenu extends Component {
  onClose = () => {
    this.props.onClose();
  };
  searchContent = (e) => {
    e.preventDefault();
    let val = document.querySelector(".navbar-menu.navbar .searchInput").value;
    if (!val)
      val = document.querySelector(".navbar-menu.drawer .searchInput").value;
    if (val !== "") window.location.href = "/szukaj?q=" + val;
  };
  render() {
    const { t } = this.props;
    if (this.props.auth.authenticated) {
      return (
        <div
          className={`navbar-menu ${
            this.props.auth.user.role === "admin" ? "admin-logged-in" : ""
          } ${this.props.drawer ? "drawer" : "navbar"}`}
        >
          <ul className="menu">
            {showSearchbar ? (
              <li className="search-input">
                <a>
                  <form>
                    <input
                      className="searchInput"
                      type="text"
                      // placeholder={t("nav.search_placeholder")}
                    />
                    <button type="submit" onClick={this.searchContent}>
                      <img src={searchIcon} alt="Ikona wyszukiwania" />
                    </button>
                  </form>
                </a>
              </li>
            ) : null}
            {this.props.auth.user.role === "admin" ? (
              <li className="admin-panel-url">
                <Link to="/teapp-admin">
                  Admin
                  <img
                    src={settingsIcon}
                    alt="Ikona panelu administracyjnego"
                  />
                </Link>
                <ul>
                  {adminMenuItems.map((i) => (
                    <li key={i.key} onClick={this.onClose}>
                      <a href={`/teapp-admin?view=${i.key}`}>{i.label}</a>
                    </li>
                  ))}
                </ul>
              </li>
            ) : null}
            <li>
              <Link to="/konto">
                {t("nav.your_account")}
                <img src={userIcon} alt="Ikona użytkownika" />
              </Link>
            </li>
            <li>
              <a onClick={this.props.signOutUser}>
                {t("nav.logout")}
                <img src={logoutIcon} alt="Ikona wylogowania" />
              </a>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="navbar-menu">
          <ul>
            <li>
              <Link to={`/logowanie?redirect=${window.location.pathname}`}>
                {t("nav.login")}
                <img src={loginIcon} alt="Ikona logowania" />
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signOutUser })(
  withTranslation()(NavbarMenu)
);
