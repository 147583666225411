import React from "react";
import { connect } from "react-redux";
import { Alert, Modal, Popconfirm, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import GalleryImageForm from "../forms/GalleryImageForm";
import { imageServerUrl } from "../../../../../theme-variables";

class GalleryImagesModal extends React.Component {
  render() {
    const { gallery, visible, closeModal } = this.props;
    return (
      <Modal
        title={`${gallery.name} - zarządzaj zdjęciami`}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        style={{ top: 30 }}
      >
        {gallery.images.length > 0 ? (
          <Table
            size="small"
            dataSource={gallery.images.map((t) => {
              t.key = t.id;
              return t;
            })}
            pagination={false}
          >
            <Column
              title="Podgląd"
              dataIndex="thumb_url"
              key="url"
              render={(v, r) => {
                return (
                  <img
                    height="80"
                    src={imageServerUrl + v}
                    alt={`podgląd zdjęcia ${r.id}`}
                  />
                );
              }}
            />
            <Column
              title="Operacje"
              key="action"
              render={(v, r) => (
                <>
                  <Popconfirm
                    title={`Czy na pewno chcesz usunąć zdjęcie?`}
                    onConfirm={() => this.props.deleteGalleryImage(r.id)}
                    okText="Tak"
                    cancelText="Nie"
                  >
                    <DeleteOutlined
                      key="delete"
                      style={{ fontSize: 20, margin: "0 10px" }}
                    />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        ) : (
          <Alert message="Brak zdjęć" type="info" />
        )}
        {gallery.images.length < 5 ? (
          <GalleryImageForm
            galleryId={this.props.gallery.id}
            onSubmit={this.props.addGalleryImage}
          />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImagesModal);
