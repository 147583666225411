import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Checkbox, notification, Skeleton } from "antd";
import { getConfig } from "../../../actions/appConfigActions";
import { getPacket } from "../../../actions/packetsActions";
import {
  buyTicket,
  clearPaymentError,
} from "../../../actions/packetPaymentsActions";
import "./PaymentPage.scss";
import Wrapper from "../../wrappers/Wrapper";
import InvoiceForm from "./InvoiceForm";
import {
  allowCompanyInvoices,
  allowInvoices,
  paymentCheckbox,
  paymentCheckboxEn,
  paymentVatInfo,
  paymentVatInfoEn,
} from "../../../theme-variables";
import tc from "../../../helpers/translateContent";
import { Link } from "react-router-dom";

class PacketPaymentPage extends React.Component {
  state = {
    packetId: null,
    ticketType: "standard",
    needInvoice: false,
    additionalCb: false,
    additionalCbError: false,
    invoiceData: {
      street: this.props.user.street,
      postal_code: this.props.user.postal_code,
      country_code: this.props.user.country_code,
      city: this.props.user.city,
      state: this.props.user.state,
      nip: this.props.user.nip,
      company: this.props.user.company,
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
    },
    invoiceType: "person",
  };
  componentDidMount() {
    const packetId = this.props.match.params.productId;
    this.setState({ packetId });
    this.props.getPacket(packetId);
    this.props.getConfig();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.packetPayments.loading &&
      !this.props.packetPayments.success &&
      this.props.packetPayments.error
    ) {
      notification.error({
        message: this.props.t("const.error_occured"),
        description: this.props.packetPayments.error,
      });
      this.props.clearPaymentError();
    }
    return null;
  };
  componentWillUnmount = () => {
    this.props.clearPaymentError();
  };
  renderPacketDescription = () => {
    const { ticketsExpirationTime, packet, user, t } = this.props;
    const { title, description, translations, events, videos } = packet;

    const { birthday } = user;
    return (
      <>
        <h2>{tc(title, "title", translations)}</h2>
        <p
          className="product-description"
          dangerouslySetInnerHTML={{
            __html: tc(description, "description", translations),
          }}
        />
        {events.length || videos.length ? (
          <div className="packets-products">
            {events.map((e) => {
              if (e.included)
                return (
                  <Link key={`e-${e.id}`} to={`/wydarzenie/${e.slug || e.id}`}>
                    <div className="packets-product">
                      <div className="title">
                        {tc(e.title, "title", e.translations)}
                      </div>
                      {e.subtitle ? (
                        <div className="subtitle">
                          {tc(e.subtitle, "subtitle", e.translations)}
                        </div>
                      ) : null}
                      <div className="description">
                        {t("paymentpage.watch")} —{" "}
                        {e.time_from !== "00:00:00"
                          ? moment(
                              e.date_from + " " + e.time_from,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD.MM.YYYY, H:mm")
                          : moment(e.date_from, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                        {e.countryBlock ? (
                          <span className="error">
                            {t("paymentpage.country_blocked")}
                          </span>
                        ) : null}
                        {birthday &&
                        moment().diff(birthday, "years") < e.age_restriction ? (
                          <span className="error">
                            {t("paymentpage.age_restriction")}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                );
              return null;
            })}
            {videos.map((v) => {
              const {
                tickets_expiration_time,
                end_date,
                available_from,
                countryBlock,
                age_restriction,
                id,
                slug,
                translations,
                title,
                subtitle,
                included,
              } = v;
              const expTimeOverriden = tickets_expiration_time
                ? tickets_expiration_time
                : tickets_expiration_time === 0
                ? null
                : ticketsExpirationTime;
              if (included)
                return (
                  <Link to={`/wydarzenie/${slug || id}`}>
                    <div className="packets-product" key={`v-${id}`}>
                      <div className="title">
                        {tc(title, "title", translations)}
                      </div>
                      {subtitle ? (
                        <div className="subtitle">
                          {tc(subtitle, "subtitle", translations)}
                        </div>
                      ) : null}
                      <div className="description">
                        {t("paymentpage.vod")}
                        {expTimeOverriden || available_from ? <> — </> : null}
                        {expTimeOverriden ? (
                          end_date &&
                          moment(end_date).isBefore(
                            moment().add(expTimeOverriden, "hours")
                          ) ? (
                            <>
                              {t("paymentpage.ticket_to_short")}{" "}
                              {this.renderDate(end_date)}{" "}
                              {t("paymentpage.to_hour")}{" "}
                              {moment(end_date).format("H:mm")}
                            </>
                          ) : (
                            <>
                              {t("paymentpage.ticket_to2_short")}{" "}
                              {expTimeOverriden} {t("paymentpage_hour_from")}{" "}
                              {moment(available_from).isAfter(moment())
                                ? t("paymentpage.premiere")
                                : t("paymentpage.payment_packet")}
                            </>
                          )
                        ) : available_from &&
                          moment(available_from).isAfter(moment()) ? (
                          <>
                            {t("ticket_from_short")}{" "}
                            {moment(available_from).format("DD.MM.YYYY, H:mm")}
                          </>
                        ) : null}
                        {countryBlock ? (
                          <span className="error">
                            {t("paymentpage.country_blocked")}
                          </span>
                        ) : null}
                        {birthday &&
                        moment().diff(birthday, "years") < age_restriction ? (
                          <span className="error">
                            {t("paymentpage.age_restriction")}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                );
              return null;
            })}
          </div>
        ) : null}
      </>
    );
  };
  renderPrice = () => {
    const { price } = this.props.packet;
    return (
      <div className="final-price">
        {parseFloat(price).toFixed(2)} zł
        <span>
          {this.props.i18n.language === "pl"
            ? paymentVatInfo
            : paymentVatInfoEn}
        </span>
      </div>
    );
  };
  getReasonWhyNotAvailable = () => {
    const { t } = this.props;
    const {
      end_date,
      events,
      videos,
      force_visibility,
      age_restriction,
      countryBlock,
    } = this.props.packet;
    let reason = null;

    const { birthday } = this.props.user;
    if (birthday && moment().diff(birthday, "years") < age_restriction)
      reason = t("paymentpage.age_restriction");

    if (countryBlock) reason = t("paymentpage.country_blocked_packet");

    if (
      !events.filter((e) => e.included).length &&
      !videos.filter((v) => v.included).length
    )
      reason = t("paymentpage.no_content_available");

    if (
      (events.filter((e) => !e.included).length ||
        events.filter((e) => !e.included).length) &&
      !force_visibility
    )
      reason = t("paymentpage.some_content_expired");

    if (!!end_date && moment(end_date).isBefore(moment()))
      reason = t("paymentpage.packet_not_available");

    if (!reason) return null;
    return (
      <>
        <Button className="payment-button red" disabled>
          {reason}
        </Button>
        <small>{t("paymentpage.after_packet_payment")}</small>
      </>
    );
  };
  renderPaymentButton = () => {
    const { packetPayments, t, packet } = this.props;
    const { access, paymentStatus } = packet;
    if (!paymentStatus) return null;
    if (
      !access &&
      ["CANCELED", "NOT_STARTED", "UNDEFINED", "REFUNDED", null].some((v) =>
        paymentStatus.includes(v)
      )
    )
      return (
        <>
          <Button
            type="primary"
            className={`payment-button white`}
            loading={packetPayments.loading}
            onClick={() => this.handlePayment()}
          >
            {t("paymentpage.go_to_payment")}
          </Button>
          <small>{t("paymentpage.after_packet_payment")}</small>
        </>
      );
    else if (
      !access &&
      ["PENDING", "WAITING_FOR_CONFIRMATION", null].some((v) =>
        paymentStatus.includes(v)
      )
    )
      return (
        <Button
          type="primary"
          className={`payment-button orange`}
          loading={packetPayments.loading}
          disabled
        >
          {t("paymentpage.payment_pending")}
        </Button>
      );
    else if (access) {
      return (
        <>
          <Button
            type="primary"
            className={`payment-button green`}
            loading={packetPayments.loading}
            disabled
          >
            {t("videodesc.access")}
          </Button>
        </>
      );
    }
  };
  onInvoiceCbChange = (checked) => {
    this.setState({ needInvoice: checked });
  };
  onAdditionalCbChange = (checked) => {
    this.setState({ additionalCb: checked, additionalCbError: false });
  };
  changeInvoiceData = (newState) => {
    this.setState({ invoiceData: { ...this.state.invoiceData, ...newState } });
  };
  changeInvoiceType = (val) => {
    this.setState({ invoiceType: val });
  };
  handlePayment = () => {
    const {
      postal_code,
      city,
      state,
      country_code,
      street,
      company,
      firstname,
      lastname,
      nip,
    } = this.state.invoiceData;
    const { invoiceType, needInvoice, additionalCb } = this.state;
    const { t } = this.props;

    if (!additionalCb) {
      this.setState({ additionalCbError: true });
      return;
    }

    let finalInvoiceData = {};
    if (needInvoice) {
      let newState = {};
      if (allowCompanyInvoices && invoiceType === "company") {
        if (!company) newState.company_error = t("form.validate.required");
        else if (company.length > 100)
          newState.company_error = t("form.validate.wrong");
        if (!nip) newState.nip_error = t("form.validate.required");
        else if (nip.length > 100 || !/^[0-9]*$/.test(nip))
          newState.nip_error = t("form.validate.wrong");
      } else {
        if (!firstname) newState.firstname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(firstname))
          newState.firstname_error = t("form.validate.wrong");
        if (!lastname) newState.lastname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(lastname))
          newState.lastname_error = t("form.validate.wrong");
      }
      if (!postal_code)
        newState.postal_code_error = t("form.validate.required");
      if (!city) newState.city_error = t("form.validate.required");
      if (!state) newState.state_error = t("form.validate.required");
      if (!country_code)
        newState.country_code_error = t("form.validate.required");
      if (!street) newState.street_error = t("form.validate.required");
      if (
        postal_code &&
        (postal_code.length > 10 || /<[a-z/][\s\S]*>/.test(postal_code))
      )
        newState.postal_code_error = t("form.validate.wrong_input_max10");
      if (city && (city.length > 30 || /<[a-z/][\s\S]*>/.test(city)))
        newState.city_error = t("form.validate.wrong_input_max30");
      if (state && (state.length > 30 || /<[a-z/][\s\S]*>/.test(state)))
        newState.state_error = t("form.validate.wrong_input_max30");
      if (
        country_code &&
        (country_code.length > 2 || /<[a-z/][\s\S]*>/.test(country_code))
      )
        newState.country_code_error = t("form.validate.wrong_input_max2");

      if (Object.keys(newState).length > 0) {
        this.setState({
          invoiceData: { ...this.state.invoiceData, ...newState },
        });
        return;
      }

      finalInvoiceData = {
        postal_code,
        city,
        state,
        country_code,
        street,
        company,
        firstname,
        lastname,
        nip,
        invoiceType: allowCompanyInvoices ? invoiceType : "person",
      };
    }

    const { events, videos } = this.props.packet;
    const products = {
      events: events.map((e) => e.id),
      videos: videos.map((v) => v.id),
    };
    this.props.buyTicket(this.state.packetId, {
      ...products,
      ...finalInvoiceData,
    });
  };
  render() {
    const { t } = this.props;
    const {
      needInvoice,
      additionalCb,
      additionalCbError,
      invoiceData,
      invoiceType,
    } = this.state;
    if (!!this.props.packet && !this.getReasonWhyNotAvailable())
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            <p>
              {this.props.user.firstname}, {t("paymentpage.great_choice")} – 
              {t("paymentpage.ticket_gift")}:
            </p>
            {this.renderPacketDescription()}
            <div className="payment-checkboxes">
              {this.props.packet.price > 0 && allowInvoices ? (
                <Checkbox
                  checked={needInvoice}
                  onChange={(e) => this.onInvoiceCbChange(e.target.checked)}
                  style={{ marginBottom: 20 }}
                >
                  {t("videodesc.need_invoice")}
                </Checkbox>
              ) : null}
              {needInvoice && allowInvoices ? (
                <InvoiceForm
                  changeInvoiceData={this.changeInvoiceData}
                  changeInvoiceType={this.changeInvoiceType}
                  invoiceData={invoiceData}
                  invoiceType={invoiceType}
                />
              ) : null}
              {paymentCheckbox ? (
                <Checkbox
                  checked={additionalCb}
                  onChange={(e) => this.onAdditionalCbChange(e.target.checked)}
                >
                  <span
                    className={additionalCbError ? `cb-error` : ""}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.i18n.language === "pl"
                          ? paymentCheckbox
                          : paymentCheckboxEn || paymentCheckbox,
                    }}
                  />
                </Checkbox>
              ) : null}
            </div>
            <div className="payment-price">
              {this.renderPrice()}
              {this.renderPaymentButton()}
            </div>
          </div>
        </Wrapper>
      );
    else if (!!this.props.packet && this.getReasonWhyNotAvailable())
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            {this.renderPacketDescription()}
            {this.getReasonWhyNotAvailable()}
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    packetPayments: state.packetPayments || { loading: false },
    packet: state.packets.packets.find(
      (p) => p.id === Number.parseInt(ownProps.match.params.productId)
    ),
    ticketsExpirationTime: state.appConfig.config.tickets_expiration_time,
    user: state.auth.user,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, {
  buyTicket,
  clearPaymentError,
  getConfig,
  getPacket,
})(withTranslation()(PacketPaymentPage));
