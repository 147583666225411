import React from "react";
import moment from "moment";
import { Field, reduxForm, change } from "redux-form";
import { Tooltip, Button, DatePicker, Select, Alert, Checkbox } from "antd";
import Input from "../../../../form-components/Input";
import { InfoCircleOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { makeField } from "../../../../form-components/makeComponent";
import FormItem from "antd/lib/form/FormItem";
import { Link } from "react-router-dom";

class FreeCodeConfig extends React.Component {
  state = {
    freeCode:
      this.props.initialValues.free_code_after_signup === 1 ? true : false,
  };
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  onProductChange = (v) => {
    this.props.dispatch(change("freeCodesConfigForm", "product_id", v));
  };
  toggleSettings = (e) => {
    this.setState({ freeCode: e.target.checked });
    this.props.change("free_code_after_signup", e.target.checked);
  };
  render() {
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <Checkbox
          style={{ fontSize: "13px", margin: "5px 0" }}
          checked={this.state.freeCode}
          size="big"
          onChange={this.toggleSettings}
        >
          Darmowy kod po rejestracji i zweryfikowaniu adresu e-mail
        </Checkbox>
        {this.state.freeCode ? (
          <>
            <br />
            <br />
            <Field
              name="code_slug"
              component={Input}
              placeholder="Kod (prefiks)"
              type="text"
              suffix={
                <Tooltip title="Początek kodu rabatowego. Po prefiksie zostanie dodany unikalny ciąg znaków.">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
            <Field
              name="info"
              component={Input}
              placeholder="Zarejestruj się, aby otrzymać darmowy kod rabatowy na..."
              suffix={
                <Tooltip title="Informacja o darmowym kuponie, która wyświetli się na stronie rejestracji">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              type="text"
            />
            <Field
              name="info_en"
              component={Input}
              placeholder="Sign up for free promotion code..."
              suffix={
                <Tooltip title="Informacja o darmowym kuponie, która wyświetli się na stronie rejestracji (język angielski)">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              type="text"
            />
            <Field
              name="product_id"
              component={({ meta, input }) => {
                const error =
                  meta.touched && meta.error !== null ? meta.error : null;
                return (
                  <FormItem
                    label="Wybierz produkt:"
                    hasFeedback
                    help={error}
                    validateStatus={error ? "error" : null}
                  >
                    <Select
                      value={input.value}
                      allowClear
                      style={{ width: "100%" }}
                      showSearch
                      dropdownMatchSelectWidth={false}
                      onChange={this.onProductChange}
                      optionFilterProp={"children"}
                    >
                      <Select.Option key="any" value="any">
                        Dowolne wydarzenie/wideo
                      </Select.Option>
                      {this.props.videos.map((v) => {
                        const id = `video-${v.id}`;
                        const label = `Wideo #${v.id}: ${v.title}`;
                        return (
                          <Select.Option key={id} value={id}>
                            {label}{" "}
                            <Link
                              target="_blank"
                              to={`/wideo/${v.slug || v.id}`}
                            >
                              link
                            </Link>
                          </Select.Option>
                        );
                      })}
                      {this.props.events.map((v) => {
                        const id = `event-${v.id}`;
                        const time = v.date_to
                          ? `${moment(v.date_from).format("DD.MM")}-${moment(
                              v.date_to
                            ).format("DD.MM")}`
                          : moment(v.date_from).format("DD.MM");
                        const label = `Wydarzenie #${v.id}: ${v.title} (${time})`;
                        return (
                          <Select.Option key={id} value={id}>
                            {label}{" "}
                            <Link
                              target="_blank"
                              to={`/wydarzenie/${v.slug || v.id}`}
                            >
                              link
                            </Link>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                );
              }}
            />
            <Field
              name="discount"
              label="Zniżka"
              component={Input}
              placeholder="5 / 15%"
              type="text"
              suffix={
                <Tooltip title="Proszę podać kwotę w zł o którą zostanie obniżona cena wyjściowa. Jeżeli obniżka jest procentowa, należy na końcu dopisać symbol '%'">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
            <Field
              className="datePicker"
              showTime
              name="expiration_date"
              component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
              label={"Data wygaśnięcia kuponu:"}
              hasFeedback
              onFocus={(e) => e.preventDefault()}
              onBlur={(e) => e.preventDefault()}
            />
            {error ? <Alert message={error} type="error" showIcon /> : null}
          </>
        ) : null}
        <br />
        <br />
        <Button type="primary" htmlType="submit">
          Zapisz
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const { code, info, expiration_date, discount, product_id } = formValues;

  if (!code) errors.code = "Niepoprawny kod/prefiks";
  if (code && code.length > 30) errors.code = "Maksymalna liczba znaków to 30";

  if (!info) errors.info = "Pole wymagane. Prosimy podać opis kodu.";

  if (!expiration_date) errors.expiration_date = "Niepoprawna data.";

  if (!discount) errors.discount = "Pole wymagane.";
  if (
    discount &&
    !/^\d+(.\d{1,2})?$/.test(discount) &&
    !/^((100)|(\d{1,2}(.\d*)?))%$/.test(discount)
  )
    errors.discount = "Niepoprawna wartość.";

  if (discount && discount == 0) errors.discount = "Niepoprawna wartość.";

  if (!product_id) errors.product_id = "Pole wymagane.";
  else if (!["event-", "video-", "any"].some((v) => product_id.includes(v)))
    errors.product_id = "Niepoprawna wartość.";
  else if (!product_id.includes("any")) {
    const productData = product_id.split("-");
    if (productData.length !== 2 || isNaN(parseInt(productData[1])))
      errors.product_id = "Niepoprawna wartość.";
  }

  return errors;
};

export default reduxForm({
  form: "freeCodesConfigForm",
  enableReinitialize: true,
  validate,
})(withTranslation()(FreeCodeConfig));
