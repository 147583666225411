import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import EventCard from "./EventCard";

class FutureEvents extends React.Component {
  state = {};
  render() {
    const { t } = this.props;
    if (this.props.events.length)
      return (
        <div>
          {this.props.events
            ? this.props.events.map((event) => (
                <EventCard
                  key={event.id}
                  userRole={this.props.user.role || undefined}
                  loading={this.props.paymentStatus.loading}
                  event={event}
                  mailing={this.props.user.mailing}
                />
              ))
            : null}
        </div>
      );
    else return <h2>{t("dashboard.new_events_soon")}</h2>;
  }
}

const mapStateToProps = (state) => {
  return {
    paymentStatus: state.payments,
    user: state.auth.user || {},
  };
};

export default connect(mapStateToProps)(withTranslation()(FutureEvents));
