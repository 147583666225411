import React from "react";
import { Card } from "antd";
import qs from "query-string";
import Wrapper from "../wrappers/Wrapper";
import AdminMenu from "./menu/AdminMenu";
import ErrorHandler from "./ErrorHandler";
import VideosManager from "./panels/videos/VideosManager";
import EventsManager from "./panels/events/EventsManager";
import UsersManager from "./panels/users/UsersManager";
import PaymentsManager from "./panels/payments/PaymentsManager";
import ContentSettings from "./panels/content/ContentSettings";
import NotificationsManager from "./panels/notifications/NotificationsManager";
import StatisticsManager from "./panels/statistics/StatisticsManager";
import CategoriesManager from "./panels/categories/CategoriesManager";
import GalleriesManager from "./panels/galleries/GalleriesManager";
import AboutVersions from "./panels/about/AboutVersions";
import AdvertisementSettings from "./panels/advertisement/AdvertisementSettings";
import SlidesManager from "./panels/slides/SlidesManager";
import LimitationsSettings from "./panels/limitations/LimitationsSettings";
import CodesManager from "./panels/codes/CodesManager";
import "./AdminPanel.scss";
import FestivalSettings from "./panels/festival/FestivalSettings";
import InvoicesManager from "./panels/invoices/InvoicesManager";
import GiftsManager from "./panels/gifts/GiftsManager";
import GroupTicketsManager from "./panels/groupTickets/GroupTicketsManager";
import PacketsManager from "./panels/packets/PacketsManager";
import Help from "./panels/help/Help";

class AdminPanel extends React.Component {
  state = { view: "videos" };
  handleChange = (key) => {
    this.setState({ view: key });
  };
  componentDidMount() {
    const view = qs.parse(this.props.location.search).view;
    if (view) this.setState({ view });
  }
  renderView = () => {
    switch (this.state.view) {
      case "videos":
        return <VideosManager />;
      case "events":
        return <EventsManager />;
      case "festival":
        return <FestivalSettings />;
      case "galleries":
        return <GalleriesManager />;
      case "users":
        return <UsersManager />;
      case "limitations":
        return <LimitationsSettings />;
      case "payments":
        return <PaymentsManager />;
      case "invoices":
        return <InvoicesManager />;
      case "views":
        return <ContentSettings handleChangeView={this.handleChange} />;
      case "notifications":
        return <NotificationsManager />;
      case "statistics":
        return <StatisticsManager />;
      case "categories":
        return <CategoriesManager />;
      case "slider_settings":
        return <SlidesManager />;
      case "about_versions":
        return <AboutVersions />;
      case "help":
        return <Help />;
      case "advertisement_settings":
        return <AdvertisementSettings />;
      case "group_tickets":
        return <GroupTicketsManager />;
      case "gifts":
        return <GiftsManager />;
      case "packet":
        return <PacketsManager />;
      case "promotion_codes":
        return <CodesManager />;
      default:
        return <VideosManager />;
    }
  };
  render() {
    return (
      <Wrapper>
        <h1>Panel administracyjny</h1>
        <Card className="admin-panel" style={{ marginBottom: 30 }}>
          <div className="admin-menu-container">
            <AdminMenu
              active={this.state.view}
              handleChange={this.handleChange}
            />
          </div>
          <div className="admin-block">{this.renderView()}</div>
        </Card>
        <ErrorHandler />
      </Wrapper>
    );
  }
}

export default AdminPanel;
