import {
  GET_PACKETS_SUCCESS,
  GET_PACKETS_FAILURE,
  PACKETS_LOADING,
  DELETE_PACKET_SUCCESS,
  DELETE_PACKET_FAILURE,
  ADD_PACKET_SUCCESS,
  ADD_PACKET_FAILURE,
  UPDATE_PACKET_SUCCESS,
  UPDATE_PACKET_FAILURE,
  GET_PACKET_SUCCESS,
  GET_PACKET_FAILURE,
} from "../actions/_types";

const initialState = {
  packets: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PACKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        packets: action.payload,
      };
    case GET_PACKETS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_PACKET_SUCCESS:
      let found = false;
      let packets = state.packets.map((packet) => {
        if (packet.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return packet;
      });
      if (!found) packets.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        packets: packets,
      };
    case GET_PACKET_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_PACKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        packets: state.packets.filter((packet) => packet.id !== action.payload),
      };
    case DELETE_PACKET_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case ADD_PACKET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        packets: [action.payload, ...state.packets],
      };
    case ADD_PACKET_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case UPDATE_PACKET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        packets: state.packets.map((packet) => {
          if (packet.id === action.payload.id) return action.payload;
          return packet;
        }),
      };
    case UPDATE_PACKET_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case PACKETS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
