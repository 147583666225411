import vod, { tokenConfig } from "../apis/vod";
import {
  FREE_CODES_CONFIG_LOADING,
  GET_FREE_CODES_CONFIG_SUCCESS,
  GET_FREE_CODES_CONFIG_FAILURE,
  UPDATE_FREE_CODES_CONFIG_SUCCESS,
  UPDATE_FREE_CODES_CONFIG_FAILURE,
  FREE_CODES_FORM_ERRORS,
  CLEAR_FREE_CODES_CONFIG_STATUS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getFreeCodesSettings = (props) => (dispatch, getState) => {
  dispatch({ type: FREE_CODES_CONFIG_LOADING });
  vod
    .get(`/api/freeCodes/settings`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_FREE_CODES_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_FREE_CODES_CONFIG_FAILURE);
    });
};

export const updateFreeCodesSettings = (props) => (dispatch, getState) => {
  vod
    .put(`/api/freeCodes/settings`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_FREE_CODES_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_FREE_CODES_CONFIG_FAILURE,
        FREE_CODES_FORM_ERRORS
      );
    });
};

export const clearFreeCodesConfigStatus = () => (dispatch) => {
  dispatch({ type: CLEAR_FREE_CODES_CONFIG_STATUS });
};
