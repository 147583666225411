import vod, { tokenConfig } from "../apis/vod";
import {
  BUY_PACKET_TICKET_LOADING,
  BUY_PACKET_TICKET_SUCCESS,
  BUY_PACKET_TICKET_FAILURE,
  GET_PACKET_ORDER_SUCCESS,
  GET_PACKET_ORDER_FAILURE,
  GET_PACKET_ORDERS_SUCCESS,
  GET_PACKET_ORDERS_FAILURE,
  CLEAR_PACKET_PAYMENT_ERROR,
} from "./_types";
import handleErrors from "./handleErrors";
import history from "../history";
import { facebookPixel } from "../theme-variables";
import ReactPixel from "react-facebook-pixel";

export const buyTicket = (packetId, props) => (dispatch, getState) => {
  dispatch({
    type: BUY_PACKET_TICKET_LOADING,
    payload: packetId,
  });
  vod
    .post(`/api/packetpayments/${packetId}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: BUY_PACKET_TICKET_SUCCESS });

      if (facebookPixel && res.data.payu && res.data.order) {
        const { order } = res.data;
        ReactPixel.track("InitiateCheckout", {
          content_ids: [`packet-${order.product_id}`],
          content_category: order.type,
          contents: order.contents,
          content_type: "product",
          currency: "PLN",
          value: parseFloat(order.value),
        });
      }
      if (res.data.payu) window.location.href = res.data.payu.redirectUri;
      else history.push(`/`);
    })
    .catch((err) => {
      handleErrors(err, dispatch, BUY_PACKET_TICKET_FAILURE);
    });
};

export const getOrder = (extOrderId) => (dispatch, getState) => {
  vod
    .get(`/api/packetpayments/${extOrderId}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PACKET_ORDER_SUCCESS,
        payload: res.data.order,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_PACKET_ORDER_FAILURE);
    });
};

export const getUserOrders = () => (dispatch, getState) => {
  vod
    .get(`/api/packetpayments/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PACKET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_PACKET_ORDERS_FAILURE);
    });
};

export const getOrders = (props) => (dispatch, getState) => {
  vod
    .get(`/api/packetpayments/`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_PACKET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_PACKET_ORDERS_FAILURE);
    });
};

export const clearPaymentError = () => (dispatch) => {
  dispatch({ type: CLEAR_PACKET_PAYMENT_ERROR });
};
