import React from "react";
import { connect } from "react-redux";
import { message, Card, Skeleton, Drawer } from "antd";
import _ from "lodash";
import {
  getPackets,
  addPacket,
  deletePacket,
  updatePacket,
} from "../../../../actions/packetsActions";
import { getOrders } from "../../../../actions/packetPaymentsActions";
import { getVideos } from "../../../../actions/videosActions";
import { getEvents } from "../../../../actions/eventsActions";
import PacketList from "./lists/PacketList";
import PacketForm from "./forms/PacketForm";
import PacketsTranslationsModal from "./others/PacketsTranslationsModal";
import "../managers.scss";
import moment from "moment";
import PacketPaymentList from "./lists/PacketPaymentList";

class PacketsManager extends React.Component {
  state = {
    visible: false,
    mode: "add",
    chosenPacket: null,
    success: true,
    showTranslations: false,
  };
  componentDidMount() {
    this.props.getOrders();
    this.props.getPackets();
    this.props.getEvents();
    this.props.getVideos();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.packetSuccess && prevState.success) return { visible: false };
    return null;
  }
  handleAdd = (formValues) => {
    this.setState({ success: true });
    this.props.addPacket(formValues);
  };
  handleEdit = (formValues) => {
    this.setState({ success: true });
    this.props.updatePacket(formValues, this.state.chosenPacket);
  };
  handleSearch = (props) => {
    this.props.getOrders(props);
  };
  manageTranslationsClick = (id) => {
    this.setState({
      chosenPacket: id,
      showTranslations: true,
    });
  };
  addPacketClick = () => {
    this.setState({
      visible: true,
      mode: "add",
      chosenPacket: null,
      success: false,
    });
  };
  editPacketClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenPacket: id,
      success: false,
    });
  };
  deletePacketClick = (id) => {
    this.props.deletePacket(id);
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const { packets, events, videos } = this.props;
    const loading = this.props.loading;
    let initialValues = this.state.chosenPacket
      ? _.pick(
          packets.filter((v) => v.id === this.state.chosenPacket)[0],
          "title",
          "description",
          "price",
          "exp_date",
          "published",
          "force_visibility"
        )
      : {
          published: true,
          force_visibility: false,
        };
    if (initialValues.description)
      initialValues.description = initialValues.description.replace(
        /<br \/>/gi,
        "\n"
      );
    if (initialValues.exp_date)
      initialValues.exp_date = moment(initialValues.exp_date);

    const initialEvents = this.state.chosenPacket
      ? _.pick(
          packets.filter((p) => p.id === this.state.chosenPacket)[0],
          "events"
        ).events
      : [];
    const initialVideos = this.state.chosenPacket
      ? _.pick(
          packets.filter((p) => p.id === this.state.chosenPacket)[0],
          "videos"
        ).videos
      : [];
    const orders = this.props.orders;
    if (!loading)
      return (
        <>
          <p>
            Zakup pakietu spowoduje wygenerowanie płatności dla każdego
            wydarzenia i treści VOD.
            <br />
            Użytkownik nie będzie mógł kupić pakietu jeżeli skończyły się bilety
            na jedno z zawartych wydarzeń. W takiej sytuacji należy usunąć
            wydarzenie na które zabrakło biletów i ewentualnie obniżyć cenę
            pakietu.
          </p>
          <PacketList
            orders={orders}
            videos={this.props.videos}
            events={this.props.events}
            packets={this.props.packets}
            addPacketClick={this.addPacketClick}
            deletePacketClick={this.deletePacketClick}
            editPacketClick={this.editPacketClick}
            manageTranslationsClick={this.manageTranslationsClick}
          />
          <Card
            style={{ marginBottom: "30px" }}
            title="Zamówienia"
            className="packet-list"
          >
            <PacketPaymentList
              orders={orders}
              packets={packets}
              onSubmit={this.handleSearch}
            />
          </Card>
          <Drawer
            className="formDrawer"
            width="90%"
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
            maskClosable={false}
          >
            <h2>
              {this.state.mode === "add" ? "Dodaj pakiet" : "Edytuj pakiet"}
            </h2>
            {this.state.visible ? (
              <PacketForm
                mode={this.state.mode}
                onSubmit={
                  this.state.mode === "add" ? this.handleAdd : this.handleEdit
                }
                videos={this.props.videos}
                events={this.props.events}
                packetId={this.state.chosenPacket}
                initialValues={initialValues}
                initialEvents={initialEvents}
                initialVideos={initialVideos}
                error={this.props.packetError}
              />
            ) : null}
          </Drawer>
          {this.state.showTranslations ? (
            <PacketsTranslationsModal
              visible={this.state.showTranslations}
              event={_.pick(
                this.props.packets.filter(
                  (e) => e.id === this.state.chosenPacket
                )[0],
                "id",
                "title",
                "description"
              )}
              closeModal={() => this.setState({ showTranslations: false })}
            />
          ) : null}
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProsp = (state) => {
  return {
    orders: state.packetPayments.orders,
    packets: state.packets.packets,
    videos: state.videos.videos,
    events: state.events.events,
    loading: state.packets.loading,
    packetSuccess: state.packets.success,
    packetError: state.packets.error,
  };
};

export default connect(mapStateToProsp, {
  getOrders,
  getPackets,
  getVideos,
  getEvents,
  addPacket,
  deletePacket,
  updatePacket,
})(PacketsManager);
