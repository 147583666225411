import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
  Card,
  Button,
  Table,
  Tag,
  Popconfirm,
  DatePicker,
  Tooltip,
  Checkbox,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  TranslationOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { makeField } from "../../../../form-components/makeComponent";
import CustomSelect from "../../../../form-components/CustomSelect";
import {
  checkEventStatusByDate,
  getDatetime,
  getDatetimeRange,
} from "../../../../../helpers/productUtilities";

class EventList extends React.Component {
  state = { only_festival: false };
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  onDateRangeChange = (value) => {
    if (value !== null) {
      this.props.change("date_from", value[0].format("YYYY-MM-DD"));
      this.props.change("date_to", value[1].format("YYYY-MM-DD"));
    } else {
      this.props.change("date_from", null);
      this.props.change("date_to", null);
    }
  };
  getDataSource() {
    let dataSource = this.props.events.map((e) => {
      const eventStatus = checkEventStatusByDate(
        e.date_from,
        e.date_to,
        e.full_day,
        e.time_from,
        e.time_to
      );
      switch (eventStatus) {
        case -1:
          e.timeStatus = "Zakończone";
          break;
        case 0:
          e.timeStatus = "Wkrótce";
          break;
        case 1:
          e.timeStatus = "W trakcie";
          break;
        default:
          break;
      }
      e.key = e.id;
      return e;
    });
    if (this.state.only_festival)
      dataSource = dataSource.filter(
        (row) => row.is_festival === 1 || row.is_festival === true
      );
    return dataSource;
  }
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  render() {
    const dataSource = this.getDataSource();
    return (
      <Card
        style={{ marginBottom: "30px" }}
        title="Lista wydarzeń"
        extra={
          <Button type="primary" onClick={this.props.addEventClick}>
            Dodaj wydarzenie
          </Button>
        }
        className="event-list"
      >
        <p>
          Domyślnie wyświetlane są aktualne wydarzenia. Aby zobaczyć wydarzenia
          z przeszłości, należy określić zakres dat.
        </p>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="event-admin-form"
        >
          <Field
            label="Zakres dat"
            name="rangepicker"
            component={makeField(DatePicker.RangePicker, "DD.MM.YYYY")}
            placeholder={["Od", "Do"]}
            hasFeedback
            onChange={this.onDateRangeChange}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
          />
          <label className="video-select" htmlFor="selectVideo">
            {this.props.syncErrors && this.props.syncErrors.video_id
              ? "ERROR"
              : null}
            <Field
              id="selectVideo"
              name="video_id"
              label="Wybierz wideo"
              component={CustomSelect}
            >
              <option></option>
              {this.props.videos.map((v) => (
                <option key={v.id} value={v.id}>
                  {v.title}
                </option>
              ))}
            </Field>
          </label>
          <label htmlFor="onlyFestival">
            <Checkbox
              id="onlyFestival"
              type="checkbox"
              name="only_festival"
              style={{ marginRight: "5px" }}
              onChange={(e) =>
                this.setState({ only_festival: e.target.checked })
              }
            />
            wyświetl tylko wydarzenia festiwalowe
          </label>
          <br />
          <br />
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginBottom: "30px" }}
          >
            Szukaj
          </Button>
        </form>
        <Table
          dataSource={dataSource}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
        >
          <Column
            title="Tytuł"
            dataIndex="title"
            key="title"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            render={(v, r) => {
              return (
                <>
                  {v}
                  {r.is_festival ? <> (festiwal)</> : null}
                  <br />
                  {r.published ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Opublikowany
                    </Tag>
                  ) : (
                    <Tag icon={<CloseCircleOutlined />} color="error">
                      Nieopublikowany
                    </Tag>
                  )}
                  {!r.slug ? (
                    <Tooltip title="Proszę przejść do edycji wydarzenia i zaktualizować dane. Odnośnik zostanie wygenerowany automatycznie na podstawie tytułu, ale można go zmodyfikować.">
                      <Tag color="error">Niezdefiniowany odnośnik</Tag>
                    </Tooltip>
                  ) : null}
                  {r.auth_required ? (
                    <Tag color="warning">
                      Tylko dla zalogowanych użytkowników
                    </Tag>
                  ) : null}
                  {r.mailing_free ? (
                    Number.parseFloat(r.event_price) === 0 ? (
                      <Tag color="warning">
                        Tylko dla użytkowników zapisanych do newslettera
                      </Tag>
                    ) : (
                      <Tag color="warning">
                        Darmowy dostęp dla użytkowników zapisanych do
                        newslettera
                      </Tag>
                    )
                  ) : null}
                </>
              );
            }}
          />
          <Column
            title="Zakres dat"
            dataIndex="range"
            key="range"
            sorter={(a, b) => {
              const dateA = getDatetime(a.date_from, a.time_from);
              const dateB = getDatetime(b.date_from, b.time_from);
              return dateA.unix() - dateB.unix();
            }}
            render={(v, r) => {
              let color = "";
              switch (r.timeStatus) {
                case "W trakcie":
                  color = "success";
                  break;
                case "Zakończone":
                  color = "warning";
                  break;
                default:
                  color = "processing";
                  break;
              }
              const datetimeRange = getDatetimeRange(
                r.date_from,
                r.date_to,
                r.time_from,
                r.time_to,
                r.full_day
              );
              const time =
                datetimeRange.from.format("HH:mm") === "00:00" &&
                datetimeRange.to.format("HH:mm") === "23:59"
                  ? "Cały dzień"
                  : r.full_day
                  ? "Cały dzień CEST"
                  : this.getTimeRange(
                      datetimeRange.from.format("H:mm"),
                      datetimeRange.to.format("H:mm")
                    );
              const date = this.getDateRange(
                datetimeRange.from.format("DD.MM.YYYY"),
                datetimeRange.to.format("DD.MM.YYYY")
              );
              return (
                <div>
                  {date}
                  <br />
                  {time}
                  <br />
                  <Tag color={color}>{r.timeStatus}</Tag>
                </div>
              );
            }}
          />
          <Column
            title="Liczba biletów (max. ilość/zakupione)"
            dataIndex="available_tickets"
            key="available_tickets"
            sorter={(a, b) => a.numberOfTickets - b.numberOfTickets}
            render={(v, r) =>
              `${r.available_tickets || "n.d."}/${r.numberOfTickets} ${
                r.event_price === undefined ||
                r.event_price === null ||
                r.event_price === "0.00"
                  ? `(bilet darmowy)`
                  : ""
              }`
            }
          />
          <Column
            title="Cena"
            dataIndex="event_price"
            key="event_price"
            sorter={(a, b) => a.event_price - b.event_price}
            render={(val) => val + " zł"}
          />
          <Column
            title="Akcje"
            key="action"
            render={(text, record) => (
              <>
                <Tooltip title="Przejdź na stronę wydarzenia">
                  <Link
                    to={`/wydarzenie/${record.slug || record.id}`}
                    style={{ color: "black" }}
                  >
                    <EyeOutlined key="see" />
                  </Link>
                </Tooltip>

                <Tooltip title="Edytuj wydarzenie">
                  <EditOutlined
                    key="edit"
                    onClick={() => this.props.editEventClick(record.id)}
                  />
                </Tooltip>

                <Tooltip title="Zarządzaj tłumaczeniami">
                  <TranslationOutlined
                    key="translations"
                    onClick={() =>
                      this.props.manageTranslationsClick(record.id)
                    }
                  />
                </Tooltip>

                <Tooltip title="Usuń wydarzenie">
                  <Popconfirm
                    title={`Czy na pewno chcesz usunąć wydarzenie: ${record.title}?`}
                    onConfirm={() => this.props.deleteEventClick(record.id)}
                    okText="Tak"
                    cancelText="Nie"
                  >
                    <DeleteOutlined key="delete" />
                  </Popconfirm>
                </Tooltip>
              </>
            )}
          />
        </Table>
      </Card>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (moment(formValues.date_from).isAfter(moment(formValues.date_to)))
    errors.rangepicker = "Niepoprawna data";

  return errors;
};

export default reduxForm({ form: "eventListForm", validate })(EventList);
