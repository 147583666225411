import vod, { tokenConfig } from "../apis/vod";
import {
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAILURE,
  GET_HIDDEN_VIDEOS_SUCCESS,
  GET_HIDDEN_VIDEOS_FAILURE,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_FAILURE,
  VIDEOS_LOADING,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAILURE,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  VIDEO_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getVideos =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch(setVideosLoading());
    vod
      .get(`/api/videos`, {
        params: props,
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_VIDEOS_SUCCESS,
          payload: res.data.videos,
        });
      })
      .catch((err) => {
        handleErrors(err, dispatch, GET_VIDEOS_FAILURE);
      });
  };

export const getHiddenVideos =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch(setVideosLoading());
    vod
      .get(`/api/videos/hidden`, {
        params: props,
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_HIDDEN_VIDEOS_SUCCESS,
          payload: res.data.videos,
        });
      })
      .catch((err) => {
        handleErrors(err, dispatch, GET_HIDDEN_VIDEOS_FAILURE);
      });
  };

export const getVideo = (id) => (dispatch, getState) => {
  dispatch(setVideosLoading());
  vod
    .get(`/api/videos/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VIDEO_SUCCESS,
        payload: res.data.video,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIDEO_FAILURE);
    });
};

export const deleteVideo = (id) => (dispatch, getState) => {
  dispatch(setVideosLoading());
  vod
    .delete(`/api/videos/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_VIDEO_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_VIDEO_FAILURE);
    });
};

export const addVideo = (props) => (dispatch, getState) => {
  dispatch(setVideosLoading());
  vod
    .post(`/api/videos/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_VIDEO_SUCCESS, payload: res.data.video });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ADD_VIDEO_FAILURE, VIDEO_FORM_ERRORS);
    });
};

export const updateVideo = (props, id) => (dispatch, getState) => {
  dispatch(setVideosLoading());
  vod
    .put(`/api/videos/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_VIDEO_SUCCESS,
        payload: res.data.video,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_VIDEO_FAILURE, VIDEO_FORM_ERRORS);
    });
};
export const setVideosLoading = () => {
  return {
    type: VIDEOS_LOADING,
  };
};
