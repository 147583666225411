import { combineReducers } from "redux";
import formReducer from "./formReducer";
import authReducer from "./authReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import errorPagesReducer from "./errorPagesReducer";
import videosReducer from "./videosReducer";
import eventsReducer from "./eventsReducer";
import paymentsReducer from "./paymentsReducer";
import videoPaymentsReducer from "./videoPaymentsReducer";
import vimeoReducer from "./vimeoReducer";
import categoriesReducer from "./categoriesReducer";
import bansReducer from "./bansReducer";
import slidesReducer from "./slidesReducer";
import appConfigReducer from "./appConfigReducer";
import codesReducer from "./codesReducer";
import galleriesReducer from "./galleriesReducer";
import invoicesReducer from "./invoicesReducer";
import giftsReducer from "./giftsReducer";
import groupTicketsReducer from "./groupTicketsReducer";
import packetsReducer from "./packetsReducer";
import packetPaymentsReducer from "./packetPaymentsReducer";
import freeCodesReducer from "./freeCodesReducer";

export default combineReducers({
  auth: authReducer,
  resetPassword: resetPasswordReducer,
  form: formReducer,
  errorPage: errorPagesReducer,
  videos: videosReducer,
  events: eventsReducer,
  payments: paymentsReducer,
  videoPayments: videoPaymentsReducer,
  vimeo: vimeoReducer,
  categories: categoriesReducer,
  bans: bansReducer,
  slides: slidesReducer,
  appConfig: appConfigReducer,
  codes: codesReducer,
  galleries: galleriesReducer,
  invoices: invoicesReducer,
  groupTickets: groupTicketsReducer,
  gifts: giftsReducer,
  packets: packetsReducer,
  packetPayments: packetPaymentsReducer,
  freeCodes: freeCodesReducer,
});
