import { Button, notification } from "antd";
import React, { Component } from "react";
import { GiftOutlined } from "@ant-design/icons";
import "./GiftBlock.scss";
import { withTranslation } from "react-i18next";
import { buyGift, clearGiftPaymentError } from "../../../actions/giftsActions";
import { connect } from "react-redux";
import history from "../../../history";
import { imageServerUrl } from "../../../theme-variables";

class GiftBlock extends Component {
  state = { loading: true, success: false, error: null };
  renderPaymentButton = () => {
    return (
      <Button
        // icon={<GiftOutlined />}
        onClick={() => {
          history.push("/platnosc/prezent");
        }}
      >
        {this.props.t("videodesc.buy_gift")}
      </Button>
    );
  };
  componentWillUnmount = () => {
    this.props.clearGiftPaymentError();
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.giftPayment.loading &&
      !nextProps.giftPayment.success &&
      nextProps.giftPayment.error
    ) {
      notification.error({
        message: "Wystąpił błąd",
        description: nextProps.giftPayment.error,
      });
    }
    return null;
  }
  render() {
    const {
      gift_price,
      gift_label,
      gift_description,
      gift_description_en,
      gift_label_en,
      mobile_gift_image,
      tablet_gift_image,
      desktop_gift_image,
    } = this.props.config;
    const { language } = this.props;
    return (
      <>
        {mobile_gift_image ? (
          <div
            className={`gift-block gift-block-mobile ${
              !tablet_gift_image ? "gift-block-tablet" : ""
            } ${
              !tablet_gift_image && !desktop_gift_image
                ? "gift-block-desktop"
                : ""
            }`}
          >
            <img
              src={`${imageServerUrl}${mobile_gift_image}`}
              alt={`Baner - kup prezent: ${gift_label}`}
            />
          </div>
        ) : null}
        {tablet_gift_image ? (
          <div
            className={`gift-block gift-block-tablet ${
              !desktop_gift_image ? "gift-block-desktop" : ""
            }`}
          >
            <img
              src={`${imageServerUrl}${tablet_gift_image}`}
              alt={`Baner - kup prezent: ${gift_label}`}
            />
          </div>
        ) : null}
        {desktop_gift_image ? (
          <div className="gift-block gift-block-desktop">
            <img
              src={`${imageServerUrl}${desktop_gift_image}`}
              alt={`Baner - kup prezent: ${gift_label}`}
            />
          </div>
        ) : null}
        <div className={`gift-card`}>
          <div className="gift-title">
            {language === "pl" ? gift_label : gift_label_en}
          </div>
          <div
            className="gift-desc"
            dangerouslySetInnerHTML={{
              __html:
                language === "pl" ? gift_description : gift_description_en,
            }}
          />
          <div className="gift-price">{gift_price} zł</div>
          <div className="gift-payment-status">
            {this.renderPaymentButton()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    giftPayment: state.gifts || { loading: false },
  };
};

export default connect(mapStateToProps, {
  buyGift,
  clearGiftPaymentError,
})(withTranslation()(GiftBlock));
