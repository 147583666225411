import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_LOADING,
  CLEAR_SIGNUP,
  // ---
  SIGNIN_LOADING,
  AUTH_USER,
  SIGNIN_FAILURE,
  UNAUTH_USER,
  CLEAR_SIGNIN,
  // ---
  VERIFY_RESEND_LOADING,
  VERIFY_RESEND_SUCCESS,
  VERIFY_RESEND_FAILURE,
  // ---
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_BY_ADMIN_SUCCESS,
  UPDATE_MAILING,
  CLEAR_UPDATE_USER,
  // ---
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  AUTH_ADMIN,
} from "../actions/_types";

const initialState = {
  authenticated: false,
  signUp: {},
  signIn: {},
  verificationResend: {},
  updateUser: {},
  verification: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNUP_LOADING:
      return {
        ...state,
        signUp: {
          loading: true,
        },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signUp: {
          success: true,
        },
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        signUp: {
          error: action.payload,
        },
      };
    case CLEAR_SIGNUP:
      return {
        ...state,
        signUp: {
          loading: false,
        },
      };
    case SIGNIN_LOADING:
      return {
        ...state,
        signIn: {
          loading: true,
        },
      };
    case CLEAR_SIGNIN:
      return {
        ...state,
        signIn: {
          error: null,
          loading: false,
        },
      };
    case AUTH_ADMIN:
    case AUTH_USER:
      return {
        ...state,
        signIn: {},
        authenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        signIn: {
          error: action.payload,
        },
      };
    case UNAUTH_USER:
      return {
        ...state,
        token: null,
        user: null,
        authenticated: false,
        error: {},
      };
    case VERIFY_RESEND_LOADING:
      return {
        ...state,
        verificationResend: {
          loading: true,
        },
      };
    case VERIFY_RESEND_SUCCESS:
      return {
        ...state,
        verificationResend: {
          resendSuccess: true,
          resendStatus: true,
        },
      };
    case VERIFY_RESEND_FAILURE:
      return {
        ...state,
        verificationResend: {
          error: action.payload.message,
          resendStatus: action.payload.resend,
        },
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verification: {
          success: true,
        },
        verificationResend: {},
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verification: {
          message: action.payload.message,
          resend: action.payload.resend,
          type: action.payload.type,
        },
        verificationResend: {},
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        updateUser: {
          success: true,
          error: null,
        },
      };
    case UPDATE_MAILING:
      return {
        ...state,
        user: {
          ...state.user,
          mailing: action.payload,
        },
        updateUser: {
          success: true,
          error: null,
        },
      };
    case UPDATE_USER_BY_ADMIN_SUCCESS:
      return {
        ...state,
        updateUser: {
          success: true,
          error: null,
        },
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUser: {
          success: false,
          error: action.payload,
        },
      };
    case CLEAR_UPDATE_USER:
      return {
        ...state,
        updateUser: {},
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
