import React from "react";
import { Card, Button, message } from "antd";
import vod from "../../../apis/vod";
import { withTranslation } from "react-i18next";

class UserMailingSettings extends React.Component {
  state = { mailingFetch: null, mailing: false };
  componentDidMount() {
    this.getMailingInfo();
  }

  getMailingInfo = () => {
    vod
      .get(`/api/mailing/${this.props.user.id}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          mailingFetch: true,
          mailing: res.data.mailing,
        });
        if (this.props.onSubmit) this.props.onSubmit(res.data.mailing);
      })
      .catch((err) => {
        this.setState({ mailingFetch: false });
      });
  };

  subscribe = () => {
    const { t } = this.props;
    vod
      .post(
        `/api/mailing/${this.props.user.id}`,
        {},
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        this.getMailingInfo();
        message.success(
          this.props.admin
            ? "Użytkownik zapisany do mailingu"
            : t("userpage.mailing_on")
        );
      })
      .catch((err) => {
        this.getMailingInfo();
        message.error(t("userpage.mailing_error"));
      });
  };

  unsubscribe = () => {
    const { t } = this.props;
    vod
      .delete(`/api/mailing/${this.props.user.id}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.getMailingInfo();
        message.success(
          this.props.admin
            ? "Użytkownik wypisany z mailingu"
            : t("userpage.mailing_off")
        );
      })
      .catch((err) => {
        this.getMailingInfo();
        message.error(t("userpage.subscribe_error"));
      });
  };

  render() {
    const { t } = this.props;
    const renderMailingButton = () => {
      switch (this.state.mailing) {
        case 1:
        case true:
          return (
            <Button onClick={this.unsubscribe} className="red">
              {this.props.admin
                ? "Wypisz użytkownika z mailingu"
                : t("userpage.unsubscribe")}
            </Button>
          );
        case 0:
        case false:
          return (
            <Button onClick={this.subscribe} className="green">
              {this.props.admin
                ? "Zapisz użytkownika do mailingu"
                : t("userpage.subscribe")}
            </Button>
          );
        default:
          break;
      }
    };

    return (
      <Card
        key="user-mailing"
        title={t("userpage.mailing_title")}
        style={{ marginBottom: "30px" }}
      >
        {this.state.mailingFetch === null ? t("userpage.loading") : null}
        {this.state.mailingFetch === true ? renderMailingButton() : null}
        {this.state.mailingFetch === false ? t("userpage.fetch_error") : null}
      </Card>
    );
  }
}

export default withTranslation()(UserMailingSettings);
