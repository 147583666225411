import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message, Table, Space, Button, Input, Tag } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import vod from "../../../../../apis/vod";
import Wrapper from "../../../../wrappers/Wrapper";
import moment from "moment-timezone";
import Highlighter from "react-highlight-words";

class UsersList extends React.Component {
  state = { page: 1, users: [], usersNumber: 1 };
  componentDidMount() {
    this.fetchUsers();
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Szukaj...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Szukaj
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 110 }}
          >
            Wyczyść
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (v, r) => {
      if (dataIndex === "user") {
        if (
          r.firstname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.lastname.toString().toLowerCase().includes(v.toLowerCase())
        )
          return true;
        else return "";
      }
      return r[dataIndex]
        ? r[dataIndex].toString().toLowerCase().includes(v.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  fetchUsers = () => {
    vod
      .get(`/api/users`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          ...this.state,
          users: res.data.users,
          usersNumber: Number.parseInt(res.data.count),
        });
      })
      .catch((err) => {
        message.error("Wystąpił błąd");
      });
  };
  // onChange = () => {
  //   this.fetchUsers();
  // };
  renderRoleName(roleId, getSlug = false) {
    switch (roleId) {
      case 2:
        return !getSlug ? "standardowy" : "standard";
      case 3:
        return !getSlug ? "użytkownik premium" : "premium";
      case 4:
        return !getSlug ? "niezweryfikowany" : "unverified";
      case 5:
        return !getSlug ? "usunięty" : "deleted";
      default:
        return !getSlug ? "administrator" : "admin";
    }
  }
  render() {
    const dataSource = this.state.users.map((u) => {
      u.key = u.id;
      u.role = this.renderRoleName(u.role_id);
      u.newsletter = u.mailing ? "TAK" : "NIE";
      return u;
    });
    return (
      <Wrapper>
        <h1>Lista użytkowników</h1>
        {this.state.usersNumber ? (
          <p>Liczba użytkowników: {this.state.usersNumber}</p>
        ) : null}
        <Table
          dataSource={dataSource}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "30", "50", "100", "500"],
          }}
        >
          <Column
            title="Id"
            dataIndex="id"
            key="id"
            {...this.getColumnSearchProps("id")}
            sorter={(a, b) => a.id - b.id}
          />
          <Column
            title="Imię i nazwisko"
            dataIndex="name"
            key="name"
            sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
            {...this.getColumnSearchProps("user")}
            render={(v, r) => {
              return this.state.searchedColumn === "user" ? (
                <Link to={`/konto/${r.id}`} style={{ color: "black" }}>
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: "#ffc069",
                      padding: 0,
                    }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={`${r.firstname} ${r.lastname}`}
                  />
                </Link>
              ) : (
                <Link to={`/konto/${r.id}`} style={{ color: "black" }}>
                  {r.firstname} {r.lastname}
                </Link>
              );
            }}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            sorter={(a, b) => a.email.localeCompare(b.email)}
            {...this.getColumnSearchProps("email")}
          />
          <Column
            title="Data założenia konta"
            dataIndex="created_at"
            key="created_at"
            sorter={(a, b) =>
              moment(a.created_at).unix() - moment(b.created_at).unix()
            }
            render={(v) => moment(v).format("H:m, DD.MM.YYYY")}
          />
          <Column
            title="Liczba zakończonych zamówień"
            dataIndex="paymentStatus"
            key="paymentStatus"
            sorter={(a, b) => a.paymentStatus - b.paymentStatus}
            {...this.getColumnSearchProps("paymentStatus")}
          />
          <Column
            title="Rola"
            dataIndex="role"
            key="role"
            sorter={(a, b) => a.role.localeCompare(b.role)}
            {...this.getColumnSearchProps("role")}
          />
          <Column
            title="Typ konta"
            dataIndex="provider"
            key="provider"
            sorter={(a, b) => {
              a = a.provider || "";
              b = b.provider || "";
              return a.localeCompare(b);
            }}
            {...this.getColumnSearchProps("provider")}
            render={(v, r) => {
              if (v === "teapp")
                return (
                  <Tag color="#1010f3" style={{ marginBottom: 10 }}>
                    Konto teatr.<u>link</u>
                  </Tag>
                );
              else return "Standardowy";
            }}
          />
          <Column
            title="Subskrybent newslettera"
            dataIndex="newsletter"
            key="newsletter"
            sorter={(a, b) => a.newsletter.localeCompare(b.newsletter)}
            {...this.getColumnSearchProps("newsletter")}
          />
          <Column
            title="Kraj"
            dataIndex="country_code"
            key="country_code"
            sorter={(a, b) => {
              a = a.country_code || "";
              b = b.country_code || "";
              return a.localeCompare(b);
            }}
            {...this.getColumnSearchProps("country_code")}
            render={(v, r) => {
              return (
                <>
                  {r.country_code || "PL"}
                  <img
                    alt={`Flaga ${r.country_code}`}
                    height="17"
                    style={{ margin: "0 5px" }}
                    src={`https://cdn.ipregistry.co/flags/twemoji/${(
                      r.country_code || "PL"
                    ).toLowerCase()}.svg`}
                  />
                </>
              );
            }}
          />
          <Column
            title="Akcje"
            key="action"
            render={(text, record) => (
              <Link to={`/konto/${record.id}`} style={{ color: "black" }}>
                <EyeOutlined key="see" />
              </Link>
            )}
          />
        </Table>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
export default connect(mapStateToProps)(UsersList);
