import React from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import _ from "lodash";
import vod from "../../../../../apis/vod";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PacketTranslationForm from "../forms/PacketTranslationForm";
import { updateSyncErrors } from "redux-form";
import Column from "antd/lib/table/Column";

class PacketsTranslationsModal extends React.Component {
  state = {
    mode: "none",
    chosenTranslation: null,
    translations: [],
    initialValues: { packet_id: this.props.packet.id },
  };
  handleAdd = (formValues) => {
    vod
      .post(`/api/translations/packet`, formValues, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          mode: "none",
          translations: [...this.state.translations, res.data.translation],
          initialValues: { packet_id: this.props.packet.id },
        });
      })
      .catch((err) => {
        const error = err.response.data;
        if (error.formError)
          this.props.dispatch(
            updateSyncErrors("packetTranslationForm", error.formError)
          );
        message.error("Wystąpił błąd: " + error.message);
      });
  };
  handleEdit = (formValues) => {
    vod
      .put(
        `/api/translations/packet/${this.state.chosenTranslation}`,
        formValues,
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        this.setState({
          mode: "none",
          translations: this.state.translations.map((t) => {
            if (t.id === this.state.chosenTranslation)
              return res.data.translation;
            return t;
          }),
          initialValues: { packet_id: this.props.packet.id },
        });
      })
      .catch((err) => {
        const error = err.response.data;
        if (error.formError)
          this.props.dispatch(
            updateSyncErrors("packetTranslationForm", error.formError)
          );
        message.error("Wystąpił błąd: " + error.message);
      });
  };
  deleteTranslationClick = (translationId) => {
    vod
      .delete(`/api/translations/packet/${translationId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          translations: this.state.translations.filter(
            (t) => t.id !== translationId
          ),
          mode: "none",
          initialValues: { packet_id: this.props.packet.id },
        });
      })
      .catch((err) => message.error("Wystąpił błąd: " + err));
  };
  editTranslationClick = (translationId) => {
    vod
      .get(`/api/translations/packet/${translationId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          initialValues: _.pick(
            res.data.translation,
            "title",
            "packet_id",
            "language_code"
          ),
          mode: "edit",
          chosenTranslation: translationId,
          packet_id: this.props.packet.id,
        });
      })
      .catch((err) =>
        message.error("Wystąpił błąd przy pobieraniu tłumaczenia")
      );
  };
  fetchTranslations = (packetId) => {
    vod
      .get(`/api/packets/${packetId}/translations`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          translations: res.data.translations.map((t) => {
            t.key = t.id;
            return t;
          }),
        });
      })
      .catch((err) => {
        message.error("Wystąpił błąd:" + err);
      });
  };
  componentDidMount() {
    this.fetchTranslations(this.props.packet.id);
  }
  render() {
    const { packet, visible, closeModal } = this.props;
    return (
      <Modal
        title={`${packet.title} - zarządzaj tłumaczeniami`}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        style={{ top: 30 }}
      >
        <Button
          type="primary"
          onClick={() =>
            this.setState({
              mode: "add",
              initialValues: { packet_id: this.props.packet.id },
            })
          }
          style={{ marginBottom: 30 }}
          disabled={this.state.mode !== "none"}
        >
          Dodaj tłumaczenie
        </Button>
        {this.state.translations.length > 0 ? (
          <Table
            size="small"
            dataSource={this.state.translations.map((t) => {
              t.key = t.id;
              return t;
            })}
            pagination={false}
          >
            <Column title="Język" dataIndex="language_code" key="name" />
            <Column
              title="Operacje"
              key="action"
              render={(v, r) => (
                <>
                  <EditOutlined
                    key="edit"
                    onClick={() => this.editTranslationClick(r.id)}
                    style={{ fontSize: 20, margin: "0 10px" }}
                  />
                  <Popconfirm
                    title={`Czy na pewno chcesz usunąć tłumaczenie: ${r.language_code}?`}
                    onConfirm={() => this.deleteTranslationClick(r.id)}
                    okText="Tak"
                    cancelText="Nie"
                  >
                    <DeleteOutlined
                      key="delete"
                      style={{ fontSize: 20, margin: "0 10px" }}
                    />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        ) : (
          <Alert message="Brak tłumaczeń" type="info" />
        )}
        {this.state.mode === "edit" || this.state.mode === "add" ? (
          <>
            <Divider />
            <PacketTranslationForm
              packetId={packet.id}
              mode={this.state.mode}
              initialValues={this.state.initialValues}
              onSubmit={
                this.state.mode === "add" ? this.handleAdd : this.handleEdit
              }
            />
          </>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PacketsTranslationsModal);
